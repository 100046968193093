import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { User } from '../models/user';

@Injectable()
export class RecoveryService {
  private baseUrl: string;

    constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient) {
      this.baseUrl = baseUrl;
    }

    isRecoveryValid(recoveryId: string, userId: string): Promise<boolean> {
      return this.http.get<boolean>(this.baseUrl + 'api/PasswordRecovery/IsValid/' + recoveryId + '/' + userId).toPromise();
    }

    startRecovery(email: string): Promise<boolean> {
      return this.http.get<boolean>(this.baseUrl + 'api/PasswordRecovery/StartRecovery/' + email).toPromise();
    }

    finishRecovery(reocveryId: string, userId: string, password: string): Promise<boolean> {
      const dto = new User();
      dto.id = userId;
      dto.password = password;
      return this.http.post<boolean>(this.baseUrl + 'api/PasswordRecovery/FinishRecovery/' + reocveryId, dto).toPromise();
    }
}
