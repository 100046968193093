import { Component, OnInit } from '@angular/core';
import { Cookie } from '@ngx-toolkit/cookie';
import { StartupService } from '../../../services/startup.service';
import { Cookies } from '../../../models/config';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html'
})
export class CookieConsentComponent implements OnInit {

  @Cookie('cookieConsent')
  public cookieConsent: string;

  private _acceptMarketingAndStatistics = false;
  private _cookies: Cookies = null;

  constructor(private startupService: StartupService) { }

  ngOnInit() {
    this._cookies = this.startupService.getConfig().cookies;
  }

  public get cookies(): Cookies {
    return this._cookies;
  }

  public get hasRequired(): boolean {
    return this.cookies != null && this.cookies.required.length > 0;
  }

  public get hasMarketing(): boolean {
    return this.cookies != null && this.cookies.marketing.length > 0;
  }

  public get hasStatistics(): boolean {
    return this.cookies != null && this.cookies.statistics.length > 0;
  }

  public get acceptMarketingAndStatistics(): boolean {
    return this._acceptMarketingAndStatistics;
  }

  public set acceptMarketingAndStatistics(value: boolean) {
    this._acceptMarketingAndStatistics = value;
  }

  public marketingConsentChanged(event: any): void {
    this.acceptMarketingAndStatistics = event.currentTarget.checked;
  }

  public acceptCookies(): void {
    // prepare cookie text
    let value = 'true;';
    if (this.acceptMarketingAndStatistics) {
      value += 'true';
    } else {
      value += 'false';
    }

    this.cookieConsent = value;
  }
}
