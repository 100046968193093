import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Film } from '../models/film';

@Injectable()
export class MovieService {
  private baseUrl: string;

  constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient) {
    this.baseUrl = baseUrl;
  }

  getByFilmId(id: string): Observable<Film> {
    return this.http.get<Film>(this.baseUrl + 'api/Movie/ByFilmId/' + id);
  }

  getFilmsForFutureScreenings(): Observable<Film[]> {
    return this.http.get<Film[]>(this.baseUrl + 'api/Movie/MoviesForFutureScreenings');
  }

  getFilmsForOverview(): Observable<Film[]> {
    return this.http.get<Film[]>(this.baseUrl + 'api/Movie/MoviesForOverview');
  }

  getByScreeningId(screeningId: string): Observable<Film> {
    return this.http.get<Film>(this.baseUrl + 'api/Movie/ByScreeningId/' + screeningId);
  }

  getAll(): Observable<Film[]> {
    return this.http.get<Film[]>(this.baseUrl + 'api/Movie/All');
  }

  getFilmsForPreview(): Observable<Film[]> {
    return this.http.get<Film[]>(this.baseUrl + 'api/Movie/Preview');
  }
}
