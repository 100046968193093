import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserRegistrationResult } from '../models/enums/userRegistrationResult';
import { User } from '../models/user';
import { Observable } from 'rxjs';
import { UserRight } from '../models/enums/userRight';
import { UserUpdateResult } from '../models/enums/userUpdateResult';
import { NewsletterEntry } from '../models/newsletterEntry';
import { TimeFormatPipe } from '../helpers/TimeFormatPipe';

@Injectable()
export class UserService {
  private baseUrl: string;

  constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient, private timePipe: TimeFormatPipe) {
    this.baseUrl = baseUrl;
  }

  // tslint:disable-next-line:max-line-length
  register(
    emailAddress: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    password: string,
    acceptNewsletter: boolean
  ): Observable<UserRegistrationResult> {
    const user = new User();
    user.eMailAddress = emailAddress;
    user.firstName = firstName;
    user.lastName = lastName;
    user.phoneNumber = phoneNumber;
    user.password = password;
    user.newsletter = acceptNewsletter;

    return this.http.post<UserRegistrationResult>(
      this.baseUrl + 'api/User/Register',
      user
    );
  }

  verify(userId: string): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + 'api/User/Verify/' + userId);
  }

  getOwnUser(): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'api/User/Own');
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'api/User/All');
  }

  ban(user: User): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + 'api/User/Ban/' + user.id);
  }

  unban(user: User): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + 'api/User/Unban/' + user.id);
  }

  delete(user: User): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + 'api/User/Delete/' + user.id);
  }

  deactivate(user: User): Observable<boolean> {
    return this.http.get<boolean>(
      this.baseUrl + 'api/User/Deactivate/' + user.id
    );
  }

  activate(user: User): Observable<boolean> {
    return this.http.get<boolean>(
      this.baseUrl + 'api/User/Activate/' + user.id
    );
  }

  promoteMitarbeiter(user: User): Observable<boolean> {
    return this.http.get<boolean>(
      this.baseUrl + 'api/User/PromoteMitarbeiter/' + user.id
    );
  }

  demoteMitarbeiter(user: User): Observable<boolean> {
    return this.http.get<boolean>(
      this.baseUrl + 'api/User/DemoteMitarbeiter/' + user.id
    );
  }

  resendActivationMail(user: User): Observable<boolean> {
    return this.http.get<boolean>(
      this.baseUrl + 'api/User/ResendActivationMail/' + user.id
    );
  }

  hasUserRight(user: User, right: UserRight): boolean {
    // tslint:disable-next-line:no-bitwise
    return (user.userRights & right) === right;
  }

  update(user: User): Observable<UserUpdateResult> {
    return this.http.put<UserUpdateResult>(this.baseUrl + 'api/User/Update/' + user.id, user);
  }

  setNewsletter(userId: string, isNewsletterAccepted: boolean): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + 'api/User/Newsletter/' + userId + '/' + isNewsletterAccepted);
  }

  getNewsletterUsers(): Observable<NewsletterEntry[]> {
    return this.http.get<NewsletterEntry[]>(this.baseUrl + 'api/Newsletter/NewsletterSubscribers');
  }

  deleteInactiveUsers(): Observable<number> {
    return this.http.get<number>(this.baseUrl + 'api/User/DeleteInactive');
  }

  verifyTimeZone(): Observable<boolean> {
    const time = this.timePipe.transform(Date.now());
    return this.http.get<boolean>(this.baseUrl + 'api/User/VerifyTimeZone/' + Intl.DateTimeFormat().resolvedOptions().timeZone.replace('/', '.') + '/' + time);
  }
}
