import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Reservierung } from '../models/reservierung';
import { Sitz } from '../models/sitz';
import { ScanResult } from '../models/scanResult';

@Injectable()
export class ScanService {
  private baseUrl: string;

  constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient) {
    this.baseUrl = baseUrl;
  }

  scanBooking(qrData: string): Observable<ScanResult> {
    return this.http.post<ScanResult>(this.baseUrl + 'api/Scan/ScanBooking', { data: qrData });
  }

  confirmBooking(bookingId: string): Observable<boolean> {
      return this.http.get<boolean>(this.baseUrl + 'api/Scan/ConfirmBooking/' + bookingId);
  }

  confirmTicket(ticketId: string): Observable<boolean> {
      return this.http.get<boolean>(this.baseUrl + 'api/Scan/ConfirmTicket/' + ticketId);
  }
}