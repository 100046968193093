import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ViewBaseComponent } from '../../misc/view-base/view-base.component';
import { ComponentStatus } from '../../../models/enums/componentStatus';
import { L10nTranslationService } from 'angular-l10n';
import { StartupService } from '../../../services/startup.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html'
})
export class VerificationComponent extends ViewBaseComponent implements OnInit {
  isSuccess = false;

  verificationForm = new UntypedFormGroup({
    verificationId: new UntypedFormControl('', Validators.required)
  });

  constructor(private route: ActivatedRoute, private userService: UserService, private translation: L10nTranslationService, startupService: StartupService, titleService: Title, meta: Meta) {
    super(titleService, startupService, meta);
   }

  ngOnInit() {
    this.viewState = ComponentStatus.Loading;
    this.route.paramMap.subscribe(
      map => {
        if (map.has('id')) {
          const verificationId = map.get('id');
          this.verificationForm.get('verificationId').setValue(verificationId);
          this.verifyId();
        }
      });
  }

  onSubmit(): void {
    this.verifyId();
  }

  verifyId(): void {
    this.viewState = ComponentStatus.Processing;
    const verificationId = this.verificationForm.get('verificationId').value;
    if (verificationId !== '') {
      this.userService.verify(verificationId)
      .subscribe(res => {
        if (res) {
          this.viewState = ComponentStatus.Displaying;
          this.isSuccess = true;
        } else {
          this.errorText = this.translation.translate('Verification_Error');
        }
      });
    } else {
      this.viewState = ComponentStatus.Displaying;
    }
  }
}
