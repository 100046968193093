import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RefundFeesSelection } from '../models/enums/refundFeesSelection';

@Injectable()
export class StripeService {
  private baseUrl: string;

  constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient) {
    this.baseUrl = baseUrl;
  }

  public initiateTransfer(bookingId: string): Observable<Object> {
    return this.http.get(this.baseUrl + 'api/Stripe/CreatePayment/' + bookingId);
  }

  public getFees(sessionId: string): Observable<number> {
    return this.http.get<number>(this.baseUrl + 'api/Stripe/GetStripeFees/' + sessionId);
  }

  public cancelPaidBooking(bookingId: string, cancellationCountAdults: number, cancellationCountChildren: number, refundFeeSelection: RefundFeesSelection, refundFeeAmount: number, cancelSeatsFromLeft: boolean): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + 'api/Stripe/PaymentCancellation',
      {
        bookingId: bookingId,
        cancellationCountAdults: cancellationCountAdults,
        cancellationCountChildren: cancellationCountChildren,
        refundFeeAmount: refundFeeAmount,
        refundFeeSelection: refundFeeSelection,
        cancelSeatsFromLeft: cancelSeatsFromLeft
      });
  }
}
