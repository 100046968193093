import { NgModule } from '@angular/core';
import { SafePipe } from './helpers/SafePipe';

 @NgModule({
     imports:        [],
     declarations:   [SafePipe],
     exports:        [SafePipe],
 })

 export class PipesModule {

   static forRoot() {
      return {
          ngModule: PipesModule,
          providers: [],
      };
   }
 }
