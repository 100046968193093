import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SocialMediaLandingPage } from '../models/socialMediaLandingPage';

@Injectable()
export class SocialMediaLandingPageService {
  private baseUrl: string;

    constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient) {
      this.baseUrl = baseUrl;
    }

    getBySlug(slug: string): Promise<SocialMediaLandingPage> {
      return this.http.get<SocialMediaLandingPage>(this.baseUrl + 'api/SocialMediaLandingPage/ForSlug/' + slug).toPromise();
    }

    getAll(): Promise<SocialMediaLandingPage[]> {
      return this.http.get<SocialMediaLandingPage[]>(this.baseUrl + 'api/SocialMediaLandingPage/All').toPromise();
    }

    delete(page: SocialMediaLandingPage): Promise<boolean> {
      return this.http.get<boolean>(this.baseUrl + 'api/SocialMediaLandingPage/Delete/' + page.id).toPromise();
    }

    add(page: SocialMediaLandingPage): Promise<boolean> {
      return this.http.post<boolean>(this.baseUrl + 'api/SocialMediaLandingPage/Add', page).toPromise();
    }
}
