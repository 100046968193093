import { SitzTyp } from './enums/sitzTyp';

export class Sitz {
  id: string;
  x: number;
  y: number;
  reihe: number;
  sitzplatzNummer: number;
  kategorieId: number;
  typ: SitzTyp;
  istRaucher: boolean;
}
