import { SaalArt } from './enums/saalArt';
import { Sitz } from './sitz';
export class Vorstellung {
  constructor() {
    this.sitze = [];
  }

  id: string;
  stammKinoId: number;
  vorstellungsId: string;
  reservierungsEinstellung: string;
  saalName: string;
  vorstellungszeit: Date;
  einlasszeit: Date;
  filmId: string;
  filmWocheId: string;
  preisSchemaId: string;
  rauchervorstellung: boolean;
  bemerkung: boolean;
  aufschlagAktiv: boolean;
  aufschlagText: string;
  aufschlagSumme: number;
  aufschlagAktiv3D: boolean;
  aufschlagText3D: string;
  aufschlagSumme3D: number;
  kinderTicketsErlaubt: boolean;
  sitze: Sitz[];
  customHtml: string;
  saalArt: SaalArt;
  anzahlStehplaetze: number;
}
