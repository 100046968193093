import { Component, OnInit } from '@angular/core';
import { ViewBaseComponent } from '../../misc/view-base/view-base.component';
import { ComponentStatus } from '../../../models/enums/componentStatus';
import { Validators, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { L10nTranslationService } from 'angular-l10n';
import { passwordsEqualValidator } from '../../../helpers/passwords-equal.directive';
import { RecoveryService } from '../../../services/recovery.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StartupService } from '../../../services/startup.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-recover-finish',
  templateUrl: './recover-finish.component.html'
})
export class RecoverFinishComponent extends ViewBaseComponent implements OnInit {
  isSuccess = false;
  private _userId: string;
  private _recoveryId: string;

  recoveryForm = new UntypedFormGroup({
    password: new UntypedFormGroup({
      password1: new UntypedFormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(30)]),
      password2: new UntypedFormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(30)])
    }, passwordsEqualValidator()),
  });

  constructor(private route: ActivatedRoute, private router: Router, private recoveryService: RecoveryService, private translation: L10nTranslationService, startupService: StartupService, titleService: Title, meta: Meta) {
    super(titleService, startupService, meta);
  }

  ngOnInit() {
    this.viewState = ComponentStatus.Loading;
    console.log("Finishing");

    this.route.paramMap.subscribe(
      map => {
        if (map.has('userId') && map.has('recoveryId')) {
          this._userId = map.get('userId');
          this._recoveryId = map.get('recoveryId');

          this.recoveryService.isRecoveryValid(this._recoveryId, this._userId)
          .then(x => {
            if (x) {
              this.viewState = ComponentStatus.Displaying;
            } else {
              this.errorText = this.translation.translate('RecoveryFinish_InvalidRequest');
            }
          });
        } else {
          this.router.navigate(['/recovery']);
        }
      });
  }

  onSubmit(): void {
    this.viewState = ComponentStatus.Processing;
    if (this.recoveryForm.valid) {
      this.recoveryService.finishRecovery(this._recoveryId, this._userId, this.recoveryForm.get('password').get('password1').value)
      .then(res => {
        if (res) {
          this.isSuccess = true;
          this.viewState = ComponentStatus.Displaying;
        } else {
          this.errorText = this.translation.translate('Recovery_Error');
        }
      });
    }
  }


  // TODO read parameters from URL, send to website for verification, show new PW form, etc.
}
