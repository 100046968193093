import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { StatisticsScreeningDay } from '../models/statisticsScreeningDay';
import { StatisticsScreeningWeek } from '../models/statisticsScreeningWeek';

@Injectable()
export class StatisticsService {
  private baseUrl: string;

  constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient) {
    this.baseUrl = baseUrl;
  }

  public getStatistics(): Observable<StatisticsScreeningDay[]> {
    return this.http.get<StatisticsScreeningDay[]>(this.baseUrl + 'api/Statistics/DailyStatistics');
  }

  public getStatisticWeek(): Observable<StatisticsScreeningWeek> {
    return this.http.get<StatisticsScreeningWeek>(this.baseUrl + 'api/Statistics/WeeklyStatistics');
  }
}
