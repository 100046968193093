import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { StartupService } from './startup.service';
import { CustomHtmlContent } from '../models/customHtmlContent';

@Injectable()
export class CustomContentService {
  private baseUrl: string;

  constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient, private startupService: StartupService) {
    this.baseUrl = baseUrl;
  }

  getCustomHtmlContent(key: string): Observable<CustomHtmlContent> {
    return this.http.get<CustomHtmlContent>(this.baseUrl + 'api/CustomContent/CustomHtmlContent/' + key);
  }

  setCustomHtmlContent(key: string, content: string): Observable<void> {
    const cont = new CustomHtmlContent();
    cont.key = key;
    cont.content = content;
    return this.http.post<void>(this.baseUrl + 'api/CustomContent/CustomHtmlContent', cont);
  }
}
