import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Newsletter } from '../models/newsletter';

@Injectable()
export class NewsletterService {
  private baseUrl: string;

  constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient) {
    this.baseUrl = baseUrl;
  }

  public sendNewsletter(newsletter: Newsletter): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + 'api/Newsletter/Send', newsletter);
  }

  public testNewsletter(mail: string, newsletter: Newsletter): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + 'api/Newsletter/Test/' + mail, newsletter);
  }

  public generateNewsletter(): Observable<Newsletter> {
    return this.http.get<Newsletter>(this.baseUrl + 'api/Newsletter/Generate');
  }

}
