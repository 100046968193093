import { Component, OnInit } from '@angular/core';
import { User } from './../../../models/user';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { passwordsEqualValidatorIfRequired } from './../../../helpers/passwords-equal.directive';
import { Globals } from './../../../models/globals';
import { UserService } from './../../../services/user.service';
import { UserUpdateResult } from './../../../models/enums/userUpdateResult';
import { ViewBaseComponent } from '../../misc/view-base/view-base.component';
import { L10nTranslationService } from 'angular-l10n';
import { ComponentStatus } from '../../../models/enums/componentStatus';
import { StartupService } from '../../../services/startup.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent extends ViewBaseComponent implements OnInit {
  isPasswordChange = false;
  user: User;

  profileForm = new UntypedFormGroup({
    emailAddress: new UntypedFormControl({value: '', disabled: true}),
    firstName: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
    lastName: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
    phoneNumber: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
    password: new UntypedFormGroup({
      changePassword: new UntypedFormControl(false),
      password1: new UntypedFormControl(''),
      password2: new UntypedFormControl('')
    }, passwordsEqualValidatorIfRequired()),
    acceptNewsletter: new UntypedFormControl(false)
  });

  constructor(
    private globals: Globals,
    private userService: UserService,
    private translation: L10nTranslationService,
    startupService: StartupService,
    titleService: Title, meta: Meta) {
      super(titleService, startupService, meta);
    }

  ngOnInit() {
    this.viewState = ComponentStatus.Loading;
    this.user = this.globals.getUser();
    if (this.user != null) {
      this.profileForm.get('emailAddress').setValue(this.user.eMailAddress);
      this.profileForm.get('firstName').setValue(this.user.firstName);
      this.profileForm.get('lastName').setValue(this.user.lastName);
      this.profileForm.get('phoneNumber').setValue(this.user.phoneNumber);
      this.profileForm.get('acceptNewsletter').setValue(this.user.newsletter);

      this.viewState = ComponentStatus.Displaying;
    } else {
      this.errorText = this.translation.translate('Profile_Error');
    }
  }

  onChange(): void {
    const changePassword = <boolean>this.profileForm.get('password').get('changePassword').value;
    if (changePassword) {
      this.profileForm.get('password').get('password1').setValidators([Validators.minLength(5), Validators.maxLength(30)]);
      this.profileForm.get('password').get('password2').setValidators([Validators.minLength(5), Validators.maxLength(30)]);
    } else {
      this.profileForm.get('password').get('password1').setValidators([]);
      this.profileForm.get('password').get('password2').setValidators([]);
    }

    this.isPasswordChange = changePassword;
  }

  onSubmit(): void {
    this.viewState = ComponentStatus.Processing;

    const user = new User();
    user.id = this.globals.getUser().id;
    user.eMailAddress = this.profileForm.get('emailAddress').value;
    user.firstName = this.profileForm.get('firstName').value;
    user.lastName = this.profileForm.get('lastName').value;
    user.phoneNumber = this.profileForm.get('phoneNumber').value;

    if (this.isPasswordChange) {
      user.password = this.profileForm.get('password').get('password1').value;
    }

    user.newsletter = this.profileForm.get('acceptNewsletter').value;

    this.userService.update(user).subscribe(res => {
      if (res === UserUpdateResult.Success) {
        this.userService.getOwnUser().subscribe(usr => {
          this.globals.setUser(usr);
          this.viewState = ComponentStatus.Displaying;
        });
      } else {
        this.errorText = this.translation.translate('Profile_Error');
      }
    });
  }

  get newsletterInfoText(): string {
    return this.startupService.getConfig().newsletterInfoText;
  }

  get isNewsletterEnabled(): boolean {
    return this.startupService.getConfig().enableNewsletter;
  }
}
