import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { User } from './../../../models/user';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from './../../../services/user.service';
import { passwordsEqualValidatorIfRequired } from './../../../helpers/passwords-equal.directive';
import { UserUpdateResult } from './../../../models/enums/userUpdateResult';

@Component({
  selector: 'app-user-edit-dialog',
  templateUrl: './userEdit-dialog.component.html'
})
export class UserEditDialogComponent implements OnInit, OnChanges {
  @Input()
  userService: UserService;
  @Input()
  user: User;

  isLoading = false;
  isLoginError = false;
  isPasswordChange = false;

  profileForm = new UntypedFormGroup({
    emailAddress: new UntypedFormControl({value: '', disabled: true}),
    firstName: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
    lastName: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
    phoneNumber: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
    password: new UntypedFormGroup({
      changePassword: new UntypedFormControl(false),
      password1: new UntypedFormControl(''),
      password2: new UntypedFormControl('')
    }, passwordsEqualValidatorIfRequired()),
    acceptConditions: new UntypedFormControl([false, Validators.requiredTrue])
  });

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.loadUserData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    this.loadUserData();
  }

  private loadUserData(): void {
    if (this.user != null) {
      this.profileForm.get('emailAddress').setValue(this.user.eMailAddress);
      this.profileForm.get('firstName').setValue(this.user.firstName);
      this.profileForm.get('lastName').setValue(this.user.lastName);
      this.profileForm.get('phoneNumber').setValue(this.user.phoneNumber);
    }
  }

  onChange(): void {
    const changePassword = <boolean>this.profileForm.get('password').get('changePassword').value;
    if (changePassword) {
      this.profileForm.get('password').get('password1').setValidators([Validators.minLength(5), Validators.maxLength(30)]);
      this.profileForm.get('password').get('password2').setValidators([Validators.minLength(5), Validators.maxLength(30)]);
    } else {
      this.profileForm.get('password').get('password1').setValidators([]);
      this.profileForm.get('password').get('password2').setValidators([]);
    }

    this.isPasswordChange = changePassword;
  }

  onSubmit(): void {
    this.isLoading = true;
    const user = new User();
    user.id = this.user.id;
    user.eMailAddress = this.profileForm.get('emailAddress').value;
    user.firstName = this.profileForm.get('firstName').value;
    user.lastName = this.profileForm.get('lastName').value;
    user.phoneNumber = this.profileForm.get('phoneNumber').value;

    if (this.isPasswordChange) {
      user.password = this.profileForm.get('password').get('password1').value;
    }

    this.userService.update(user).subscribe(res => {
      if (res === UserUpdateResult.Success) {
        this.isLoading = false;
        this.activeModal.close(true);
      } else {
        this.isLoading = false;
      }
    });
  }
}
