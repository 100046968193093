import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ViewBaseComponent } from '../../misc/view-base/view-base.component';
import { L10nTranslationService } from 'angular-l10n';
import { ComponentStatus } from '../../../models/enums/componentStatus';
import { StartupService } from '../../../services/startup.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent extends ViewBaseComponent implements OnInit {
  returnUrl: string;

  loginForm = new UntypedFormGroup({
    emailAddress: new UntypedFormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]),
    password: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
  });

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthenticationService, private translation: L10nTranslationService, startupService: StartupService, titleService: Title, meta: Meta) {
    super(titleService, startupService, meta);
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.viewState = ComponentStatus.Displaying;


    this.loginForm.get('emailAddress').valueChanges.subscribe(val => {
      let mail = <string>val;
      if (!mail != null && (mail.endsWith(' ') || mail.startsWith(' '))) {
        mail = mail.trim();
        this.loginForm.get('emailAddress').setValue(mail);
      }
    });
  }

  onSubmit(): void {
    if (!this.loginForm.valid) {
      return;
    }

    this.viewState = ComponentStatus.Processing;

    const mail = this.loginForm.controls['emailAddress'].value;
    const pass = this.loginForm.controls['password'].value;
    this.authService.login(mail, pass)
      .subscribe(user => {
        if (user != null) {
          const url = this.returnUrl.toString().replace(/,/, '/');
          const success = this.router.navigate([url]);
          if (!success) {
            this.router.navigate(['/']);
          }
        } else {
          this.errorText = this.translation.translate('Login_AuthError');
        }
      });
  }
}
