import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TaxSet } from '../models/taxSet';

@Injectable()
export class TaxSetService {
  private baseUrl: string;

    constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient) {
      this.baseUrl = baseUrl;
    }

    getByScreeningId(screeningId: string): Promise<TaxSet> {
      return this.http.get<TaxSet>(this.baseUrl + 'api/TaxSet/TaxForScreening/' + screeningId).toPromise();
    }
}
