import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { passwordsEqualValidator } from '../../../helpers/passwords-equal.directive';
import { UserService } from '../../../services/user.service';
import { UserRegistrationResult } from '../../../models/enums/userRegistrationResult';
import { L10nTranslationService } from 'angular-l10n';
import { ViewBaseComponent } from '../../misc/view-base/view-base.component';
import { ComponentStatus } from '../../../models/enums/componentStatus';
import { StartupService } from '../../../services/startup.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html'
})
export class RegistrationComponent extends ViewBaseComponent implements OnInit {
  isSuccess = false;

  registrationForm = new UntypedFormGroup({
    emailAddress: new UntypedFormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]),
    firstName: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
    lastName: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
    phoneNumber: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
    password: new UntypedFormGroup({
      password1: new UntypedFormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(30)]),
      password2: new UntypedFormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(30)])
    }, passwordsEqualValidator()),
    acceptConditions: new UntypedFormControl(false, [Validators.requiredTrue]),
    acceptNewsletter: new UntypedFormControl(false)
  });

  constructor(private userService: UserService, private translation: L10nTranslationService, startupService: StartupService, titleService: Title, meta: Meta) {
    super(titleService, startupService, meta);
  }

  ngOnInit() {
    this.viewState = ComponentStatus.Displaying;

    this.registrationForm.get('emailAddress').valueChanges.subscribe(val => {
      let mail = <string>val;
      if (!mail != null && (mail.endsWith(' ') || mail.startsWith(' '))) {
        mail = mail.trim();
        this.registrationForm.get('emailAddress').setValue(mail);
      }
    });
  }

  onSubmit(): void {
    this.viewState = ComponentStatus.Processing;
    if (this.registrationForm.valid) {
      this.userService.register(
        this.registrationForm.get('emailAddress').value.trim(),
        this.registrationForm.get('firstName').value,
        this.registrationForm.get('lastName').value,
        this.registrationForm.get('phoneNumber').value,
        this.registrationForm.get('password').get('password1').value,
        this.registrationForm.get('acceptNewsletter').value
        )
        .subscribe(res => {
          if (res === UserRegistrationResult.Success) {
            this.viewState = ComponentStatus.Displaying;
            this.isSuccess = true;
          } else if (res === UserRegistrationResult.DuplicateUser) {
            this.errorText = this.translation.translate('Registration_ValidationDuplicateUser');
          } else {
            this.errorText = 'Unbekannter Fehler.';
          }

        });
    }
  }

  get newsletterInfoText(): string {
    return this.startupService.getConfig().newsletterInfoText;
  }

  get termsUrl(): string {
    return this.startupService.getConfig().clientTerms;
  }

  get isStaticPagesActive(): boolean {
    return this.startupService.getConfig().staticPagesActive;
  }
  
  get isNewsletterEnabled(): boolean {
    return this.startupService.getConfig().enableNewsletter;
  }
}
