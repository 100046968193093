import { UserRight } from './enums/userRight';

// same fields in same order as in serverside UserDto
export class User {
  id: string;
  eMailAddress: string;
  userRights: UserRight;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  newsletter: boolean;
  password: string;
  registered: Date;
  lastLogin: Date;
  token: string;
  failedLoginCount: number;
}
