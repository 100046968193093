import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'timeFormat'
})
@Injectable()
export class TimeFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
     return super.transform(value, 'HH:mm');
  }
}
