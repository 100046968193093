import { Vorstellung } from '../models/vorstellung';
import { Sitz } from '../models/sitz';
import { SaalArt } from '../models/enums/saalArt';

export class ScreeningSerializer {
  fromJson(json: any): Vorstellung {
    if (json == null) {
      return null;
    }

    const screening = new Vorstellung();
    screening.id = json.id;
    screening.vorstellungsId = json.vorstellungsId;
    screening.saalName = json.saalName;
    screening.filmId = json.filmId;
    screening.filmWocheId = json.filmWocheId;
    screening.preisSchemaId = json.preisSchemaId;
    screening.aufschlagAktiv = json.aufschlagAktiv;
    screening.aufschlagSumme = json.aufschlagSumme;
    screening.aufschlagText = json.aufschlagText;
    screening.aufschlagAktiv3D = json.aufschlagAktiv3D;
    screening.aufschlagSumme3D = json.aufschlagSumme3D;
    screening.aufschlagText3D = json.aufschlagText3D;
    screening.kinderTicketsErlaubt = json.kinderTicketsErlaubt;
    screening.bemerkung = json.bemerkung;
    screening.rauchervorstellung = json.rauchervorstellung;
    screening.reservierungsEinstellung = json.reservierungsEinstellung;
    screening.customHtml = json.customHtml;
    screening.anzahlStehplaetze = json.anzahlStehplaetze;
    if (json.saalArt === 'Sitzplaetze') {
      screening.saalArt = SaalArt.Sitzplaetze;
    } else if (json.saalArt === 'Stehplaetze') {
      screening.saalArt = SaalArt.Stehplaetze;
    } else if (json.saalArt === 'Gemischt') {
      screening.saalArt = SaalArt.Gemischt;
    }

    screening.sitze = [];
    for (const s of json.sitze) {
      const sitz = new Sitz();
      sitz.id = s.id;
      sitz.kategorieId = s.kategorieId;
      sitz.reihe = s.reihe;
      sitz.sitzplatzNummer = s.sitzplatzNummer;
      sitz.typ = s.typ;
      sitz.x = s.x;
      sitz.y = s.y;
      sitz.istRaucher = s.istRaucher;
      screening.sitze.push(sitz);
    }

    const valueVorstellung = json.vorstellungszeit;
    screening.vorstellungszeit = new Date(valueVorstellung);
    const valueEinlass = json.vorstellungszeit;
    screening.einlasszeit = new Date(valueEinlass);

    return screening;
  }
}
