import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Reservierung } from '../models/reservierung';
import { Sitz } from '../models/sitz';
import { ScanResult } from '../models/scanResult';

@Injectable()
export class BookingService {
    private baseUrl: string;

    constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient) {
        this.baseUrl = baseUrl;
    }

    getOnlinePaid(): Observable<Reservierung[]> {
        return this.http.get<Reservierung[]>(this.baseUrl + 'api/Booking/OnlinePaid');
    }

    get(id: string): Observable<Reservierung> {
        return this.http.get<Reservierung>(this.baseUrl + 'api/Booking/Get/' + id);
    }

    create(booking: Reservierung): Observable<Reservierung> {
        return this.http.post<Reservierung>(this.baseUrl + 'api/Booking/Create', booking);
    }

    getOwn(): Observable<Reservierung[]> {
        return this.http.get<Reservierung[]>(this.baseUrl + 'api/Booking/Own');
    }

    confirm(bookingId: string): Observable<boolean> {
        return this.http.get<boolean>(this.baseUrl + 'api/Booking/Confirm/' + bookingId);
    }

    confirmCash(bookingId: string): Observable<boolean> {
        return this.http.get<boolean>(this.baseUrl + 'api/Booking/ConfirmCash/' + bookingId);
    }

    delete(bookingId: string): Observable<boolean> {
        return this.http.get<boolean>(this.baseUrl + 'api/Booking/Delete/' + bookingId);
    }

    getBookedSeatsForScreening(screeningId: string): Observable<Reservierung> {
        return this.http.get<Reservierung>(this.baseUrl + 'api/Booking/BookedSeatsForScreening/' + screeningId);
    }

    verifyPaymentPaypal(bookingId: string, paymentID: string): Observable<boolean> {
        return this.http.get<boolean>(this.baseUrl + 'api/Booking/VerifyPaymentPaypal/' + bookingId + '/' + paymentID);
    }

    getBookingsForScreening(screeningId: string): Observable<Reservierung[]> {
        return this.http.get<Reservierung[]>(this.baseUrl + 'api/Booking/ForScreening/' + screeningId);
    }

    getDoubleBookedSeatsForScreening(screeningId: string): Observable<Sitz[]> {
        return this.http.get<Sitz[]>(this.baseUrl + 'api/Booking/DoubleBookedSeatsForScreening/' + screeningId);
    }

    getBookingsForBlock(screeningId: string): Observable<Reservierung[]> {
        return this.http.get<Reservierung[]>(this.baseUrl + 'api/Booking/ForBlock/' + screeningId);
    }

    downloadTicket(bookingId: string): Observable<Blob> {
        return this.http.get<Blob>(this.baseUrl + 'api/Booking/PDFTicketForBooking/' + bookingId, { responseType: 'blob' as 'json' });
    }

    downloadInvoice(bookingId: string): Observable<Blob> {
        return this.http.get<Blob>(this.baseUrl + 'api/Invoice/PDFForBooking/' + bookingId, { responseType: 'blob' as 'json' });
    }

    getOpenBooking(screeningId: string): Observable<Reservierung> {
        return this.http.get<Reservierung>(this.baseUrl + 'api/Booking/OpenBooking/' + screeningId);
    }

    searchBookings(searchTerm: string): Observable<Reservierung[]> {
        return this.http.get<Reservierung[]>(this.baseUrl + 'api/Booking/Search/' + searchTerm);
    }

    getLastEPSBooking(): Observable<Reservierung> {
        return this.http.get<Reservierung>(this.baseUrl + 'api/Booking/LastEPS');
    }

    getBookingForStripe(checkoutId: string): Observable<Reservierung> {
        return this.http.get<Reservierung>(this.baseUrl + 'api/Booking/Stripe/' + checkoutId);
    }
}
