import { RegistrationComponent } from './components/user/registration/registration.component';
import { LoginComponent } from './components/user/login/login.component';
import { ProfileComponent } from './components/user/profile/profile.component';
import { VerificationComponent } from './components/user/verification/verification.component';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './helpers/auth.guard';
import { ScreeningInfoComponent } from './components/vorstellung/screening-info/screening-info.component';
import { BookingDetailsComponent } from './components/vorstellung/booking-details/booking-details.component';
import { BookingListComponent } from './components/user/booking-list/booking-list.component';
import { RecoverStartComponent } from './components/user/recover-start/recover-start.component';
import { BlockInfoComponent } from './components/vorstellung/block-info/block-info.component';
import { AuthAdminGuard } from './helpers/auth.admin.guard';
import { StaticPagesGuard } from './helpers/staticPages.guard';
import { PrivacySettingsComponent } from './components/misc/privacy-settings/privacy-settings.component';
import { RecoverFinishComponent } from './components/user/recover-finish/recover-finish.component';
import { LandingPageComponent } from './components/landingPage/landing-page.component';

const appRoutes: Routes = [
    { path: '', redirectTo: '/program/filter', pathMatch: 'full' },
    { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegistrationComponent },
    { path: 'verify/:id', component: VerificationComponent },
    { path: 'recovery', component: RecoverStartComponent },
    { path: 'recovery/:recoveryId/:userId', component: RecoverFinishComponent },
    { path: 'privacy', component: PrivacySettingsComponent },

    // admin module
    { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthAdminGuard]},

    { path: 'screening/:id', component: ScreeningInfoComponent},
    { path: 'block/:id', component: BlockInfoComponent},
    { path: 'booking/:id', component: BookingDetailsComponent, canActivate: [AuthGuard]},
    { path: 'bookings', component: BookingListComponent, canActivate: [AuthGuard]},

    // social media landing pages
    { path: 'info/:slug', component: LandingPageComponent },

    // static pages
    { path: 'page', loadChildren: () => import('./kino-page/kino-page.module').then(m => m.KinoPageModule), canActivate: [StaticPagesGuard] },

    // program module
    { path: 'program', loadChildren: () => import('./program/program.module').then(m => m.ProgramModule) },

    // eps module
    { path: 'eps', loadChildren: () => import('./eps/eps.module').then(m => m.EpsModule) },

    // stripe module
    { path: 'stripe', loadChildren: () => import('./stripe/stripe.module').then(m => m.StripeModule) },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
