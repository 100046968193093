import { Vorstellung } from './../models/vorstellung';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { ScreeningSerializer } from '../helpers/screeningSerializer';
import { ProgramByDay } from '../models/programByDay';

@Injectable()
export class ScreeningService {
  private baseUrl: string;
  private serializer = new ScreeningSerializer();

  constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient) {
    this.baseUrl = baseUrl;
  }

  getScreeningsForCancellation(): Observable<Vorstellung[]> {
    return this.http.get<Vorstellung[]>(this.baseUrl + 'api/Screening/ForCancellation').pipe(
      map(data => {
        return data.map(item => this.serializer.fromJson(item));
      })
    );
  }

  getForMovie(filmId: string): Observable<Vorstellung[]> {
    return this.http.get<Vorstellung[]>(this.baseUrl + 'api/Screening/ForMovie/' + filmId).pipe(
      map(data => {
        return data.map(item => this.serializer.fromJson(item));
      })
    );
  }


  getByScreeningId(id: string): Observable<Vorstellung> {
    return this.http.get<Vorstellung>(this.baseUrl + 'api/Screening/ByScreeningId/' + id)
    .pipe(map(data => this.serializer.fromJson(data)));
  }

  getNextScreening(id: string): Observable<Vorstellung> {
    return this.http.get<Vorstellung>(this.baseUrl + 'api/Screening/Next/' + id)
    .pipe(map(data => this.serializer.fromJson(data)));
  }

  getPreviousScreening(id: string): Observable<Vorstellung> {
    return this.http.get<Vorstellung>(this.baseUrl + 'api/Screening/Previous/' + id)
    .pipe(map(data => this.serializer.fromJson(data)));
  }

  getFutureScreenings(): Observable<Vorstellung[]> {
    return this.http.get<Vorstellung[]>(this.baseUrl + 'api/Screening/FutureScreenings').pipe(
      map(data => {
        return data.map(item => this.serializer.fromJson(item));
      })
    );
  }

  getProgramByDay(): Observable<ProgramByDay> {
    return this.http.get<ProgramByDay>(this.baseUrl + 'api/Screening/ProgramByDay');
  }

  getScreeningsForOwnBookings(): Observable<Vorstellung[]> {
    return this.http.get<Vorstellung[]>(this.baseUrl + 'api/Screening/ForOwnBookings').pipe(
      map(data => {
        return data.map(item => this.serializer.fromJson(item));
      })
    );
  }

  getScreeningsForBlock(screeningId: string): Observable<Vorstellung[]> {
    return this.http.get<Vorstellung[]>(this.baseUrl + 'api/Screening/ForBlock/' + screeningId).pipe(
      map(data => {
        return data.map(item => this.serializer.fromJson(item));
      })
    );
  }

  getAvailableStandingTickets(screeningId: string): Observable<number> {
    return this.http.get<number>(this.baseUrl + 'api/Screening/AvailableStandingTickets/' + screeningId);
  }

  async getEmailList(screeningId: string): Promise<string> {
    return await this.http.get(this.baseUrl + 'api/Screening/CustomerMailsForScreening/' + screeningId, { responseType: 'text' }).toPromise();
  }
}
