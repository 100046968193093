import { AdminService } from './services/admin.service';
import { ScreeningService } from './services/screening.service';
import { Globals } from './models/globals';
import { DialogService } from './services/dialog-service.service';
import { AuthenticationService } from './services/authentication.service';
import { AuthGuard } from './helpers/auth.guard';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieModule } from '@ngx-toolkit/cookie';
import { L10nLoader, L10nTranslationModule, L10nIntlModule, L10nRoutingModule } from 'angular-l10n';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { JwtModule } from '@auth0/angular-jwt';
import { ArchwizardModule } from 'angular-archwizard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { LoginComponent } from './components/user/login/login.component';
import { RegistrationComponent } from './components/user/registration/registration.component';
import { ProfileComponent } from './components/user/profile/profile.component';

import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { routing } from './app.routing';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UserService } from './services/user.service';
import { VerificationComponent } from './components/user/verification/verification.component';
import { LoginDialogComponent } from './components/dialogs/login-dialog/login-dialog.component';
import { StartupService } from './services/startup.service';
import { DataService } from './services/storage.service';
import { ScreeningInfoComponent } from './components/vorstellung/screening-info/screening-info.component';
import { PricingService } from './services/pricing.service';
import { MovieService } from './services/movie.service';
import { UserEditDialogComponent } from './components/dialogs/userEdit-dialog/userEdit-dialog.component';
import { BookingDetailsComponent } from './components/vorstellung/booking-details/booking-details.component';
import { BookingService } from './services/booking.service';
import { SeatingPlanComponent } from './components/vorstellung/seating-plan/seating-plan.component';
import { StandingInfoComponent } from './components/vorstellung/standing-info/standing-info.component';
import { BookingListComponent } from './components/user/booking-list/booking-list.component';
import { ViewBaseComponent } from './components/misc/view-base/view-base.component';
import { RecoverStartComponent } from './components/user/recover-start/recover-start.component';
import { RecoverFinishComponent } from './components/user/recover-finish/recover-finish.component';
import { BlockInfoComponent } from './components/vorstellung/block-info/block-info.component';
import { BarcodeDialogComponent } from './components/dialogs/barcode-dialog/barcode-dialog.component';
import { QRCodeDialogComponent } from './components/dialogs/qrcode-dialog/qrcode-dialog.component';
import { TimeFormatPipe } from './helpers/TimeFormatPipe';
import { DateFormatPipe } from './helpers/DateFormatPipe';
import { AuthAdminGuard } from './helpers/auth.admin.guard';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { StaticPagesGuard } from './helpers/staticPages.guard';
import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { NewsletterService } from './services/newsletter.service';
import { PipesModule } from './pipes.module';
import { CookieConsentComponent } from './components/misc/cookie-consent/cookie-consent.component';
import { PrivacySettingsComponent } from './components/misc/privacy-settings/privacy-settings.component';
import { CustomContentService } from './services/customContent.service';
import { EPSService } from './services/eps.service';
// import { JSErrorHandler } from './helpers/jsErrorHandler';
import { KonvaModule } from 'ng2-konva';
import { l10nConfig, HttpTranslationLoader } from './helpers/httpTranslationLoader';
import { RecoveryService } from './services/recovery.service';
import { TaxSetService } from './services/taxSetService';
import { StripeService } from './services/stripe.service';
import { StatisticsService } from './services/statisticsService';
import { ScanService } from './services/scan.service';
import { SocialMediaLandingPageService } from './services/socialMediaLandingPage.service';
import { LandingPageComponent } from './components/landingPage/landing-page.component';

registerLocaleData(localeDe);     // de-DE

export function startupServiceFactory(startupService: StartupService): Function {
  return () => startupService.load();
}

export function tokenGetter() {
  const token = localStorage.getItem('token');
  return token;
}

export function initL10n(l10nLoader: L10nLoader): Function {
  return () => l10nLoader.init();
}

@NgModule({
    declarations: [
        DateFormatPipe,
        TimeFormatPipe,
        AppComponent,
        NavMenuComponent,
        LoginComponent,
        RegistrationComponent,
        ProfileComponent,
        VerificationComponent,
        LoginDialogComponent,
        UserEditDialogComponent,
        BarcodeDialogComponent,
        QRCodeDialogComponent,
        ScreeningInfoComponent,
        BookingDetailsComponent,
        SeatingPlanComponent,
        StandingInfoComponent,
        BookingListComponent,
        ViewBaseComponent,
        RecoverStartComponent,
        RecoverFinishComponent,
        BlockInfoComponent,
        CookieConsentComponent,
        PrivacySettingsComponent,
        LandingPageComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        ArchwizardModule,
        DragDropModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        L10nTranslationModule.forRoot(l10nConfig, { translationLoader: HttpTranslationLoader }),
        L10nIntlModule,
        // L10nValidationModule.forRoot({ validation: LocaleValidation }),
        L10nRoutingModule.forRoot(),
        CookieModule.forRoot(),
        routing,
        NgbModule,
        KonvaModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter
            }
        }),
        MonacoEditorModule,
        PipesModule.forRoot(),
        BrowserAnimationsModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [StartupService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initL10n,
            deps: [L10nLoader],
            multi: true
        },
        { provide: LOCALE_ID, useValue: 'de-DE' },
        Location, { provide: LocationStrategy, useClass: PathLocationStrategy },
        Globals,
        AuthGuard,
        AuthAdminGuard,
        StaticPagesGuard,
        // // // START // Inject own services // START // // //
        AuthenticationService,
        DialogService,
        UserService,
        StartupService,
        DataService,
        ScreeningService,
        PricingService,
        MovieService,
        BookingService,
        AdminService,
        NewsletterService,
        CustomContentService,
        EPSService,
        TimeFormatPipe,
        DateFormatPipe,
        RecoveryService,
        TaxSetService,
        StripeService,
        StatisticsService,
        ScanService,
        SocialMediaLandingPageService,
        // // // END // Inject own services // END // // //
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
        // {provide: ErrorHandler, useClass: JSErrorHandler}
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
  constructor(public l10nLoader: L10nLoader) {
    this.l10nLoader.init();
  }
 }
