import { Component, OnInit } from '@angular/core';
import { Cookie } from '@ngx-toolkit/cookie';
import { Cookies } from '../../../models/config';
import { StartupService } from '../../../services/startup.service';

@Component({
  selector: 'app-privacy-settings',
  templateUrl: './privacy-settings.component.html'
})
export class PrivacySettingsComponent implements OnInit {

  @Cookie('cookieConsent')
  public cookieConsent: string;
  private _cookies: Cookies = null;

  constructor(private startupService: StartupService) { }

  ngOnInit() {
    this._cookies = this.startupService.getConfig().cookies;
  }

  public get cookies(): Cookies {
    return this._cookies;
  }

  public get hasRequired(): boolean {
    return this.cookies != null && this.cookies.required.length > 0;
  }

  public get hasMarketing(): boolean {
    return this.cookies != null && this.cookies.marketing.length > 0;
  }

  public get hasStatistics(): boolean {
    return this.cookies != null && this.cookies.statistics.length > 0;
  }

  get acceptMarketingAndStatistics(): boolean {
    const accepted = this.cookieConsent != null && this.cookieConsent !== '' && this.cookieConsent.endsWith('true');
    return accepted;
  }

  set acceptMarketingAndStatistics(value: boolean) {
    this.cookieConsent = 'true;' + (value ? 'true' : 'false');
  }

  public marketingConsentChanged(event: any): void {
    this.acceptMarketingAndStatistics = event.currentTarget.checked;
  }

}
