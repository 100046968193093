import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StartupService } from '../services/startup.service';

@Injectable()
export class StaticPagesGuard implements CanActivate {

    constructor(private router: Router, private startupService: StartupService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const config = this.startupService.getConfig();
        if (!config.staticPagesActive) {
          // not logged in so redirect to login page with the return url
          this.router.navigate(['/']);
          return false;
        }

        return true;
    }
}
