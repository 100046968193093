import { Component, OnInit } from '@angular/core';
import { BookingService } from '../../../services/booking.service';
import { ScreeningService } from '../../../services/screening.service';
import { Reservierung } from '../../../models/reservierung';
import { ComponentStatus } from '../../../models/enums/componentStatus';
import { Vorstellung } from '../../../models/vorstellung';
import { Film } from '../../../models/film';
import { MovieService } from '../../../services/movie.service';
import { Sitz } from '../../../models/sitz';
import { Reservierungsstatus } from '../../../models/enums/reservierungsstatus';
import { ViewBaseComponent } from '../../misc/view-base/view-base.component';
import { L10nTranslationService } from 'angular-l10n';
import { forkJoin } from 'rxjs';
import { DialogService } from '../../../services/dialog-service.service';
import {saveAs as importedSaveAs} from 'file-saver';
import { StartupService } from '../../../services/startup.service';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { SaalArt } from '../../../models/enums/saalArt';

@Component({
  selector: 'app-booking-list',
  templateUrl: './booking-list.component.html'
})
export class BookingListComponent extends ViewBaseComponent implements OnInit {
  bookings: Reservierung[];
  screenings: Vorstellung[];
  movies: Film[];

  constructor(
    private bookingService: BookingService,
    private screeningService: ScreeningService,
    private movieService: MovieService,
    private translation: L10nTranslationService,
    private dialogService: DialogService,
    private router: Router,
    startupService: StartupService,
    titleService: Title, meta: Meta) {
      super(titleService, startupService, meta);
  }

  ngOnInit() {
    this.viewState = ComponentStatus.Loading;
    this.loadData();
  }

  private loadData(): void {
    forkJoin([
      this.bookingService.getOwn(),
      this.screeningService.getScreeningsForOwnBookings(),
      this.movieService.getFilmsForFutureScreenings()
    ]).subscribe(results => {
      this.bookings = results[0];
      this.screenings = results[1];
      this.movies = results[2];

      if (this.bookings == null || this.movies == null || this.screenings == null) {
        this.errorText = this.translation.translate('BookingList_Error');
      } else {
        this.viewState = ComponentStatus.Displaying;
      }
    });
  }

  getSelectedRowName(booking: Reservierung): string {
    const seat = this.getSeat(booking.vorstellungsId, booking.sitzIds[0]);
    return '' + +seat.reihe;
  }

  getSelectedSeatsNames(booking: Reservierung): string {
    if (booking.sitzIds.length === 0) {
      return '';
    }

    const seatId = booking.sitzIds[0];
    let seat = this.getSeat(booking.vorstellungsId, seatId);
    let label = '' + seat.sitzplatzNummer;
    if (booking.sitzIds.length > 1) {
      seat = this.getSeat(booking.vorstellungsId, booking.sitzIds[booking.sitzIds.length - 1]);
      label += ' - ' + seat.sitzplatzNummer;
    }

    return label;
  }

  private getSeat(screeningId: string, seatId: string): Sitz {
    for (const seat of this.getScreening(screeningId).sitze) {
      if (seat.id === seatId) {
        return seat;
      }
    }

    return null;
  }

  getMovieTitle(booking: Reservierung): string {
    const screening = this.getScreening(booking.vorstellungsId);
    return this.getMovie(screening.filmId).filmTitel + ' ' + this.getMovie(screening.filmId).filmTitelZusatz;
  }

  private getMovie(movieId: string): Film {
    for (const movie of this.movies) {
      if (movie.filmId === movieId) {
        return movie;
      }
    }
    return null;
  }

  getScreening(screeningId: string): Vorstellung {
    for (const screening of this.screenings) {
      if (screening.vorstellungsId === screeningId) {
        return screening;
      }
    }
    return null;
  }

  getStatusKey(booking: Reservierung): string {
    if (booking.status === Reservierungsstatus.Reserviert || booking.status === Reservierungsstatus.InternetReserviert) {
      return 'Booking_Confirmed';
    } else if (this.isPaidBooking(booking)) {
      return 'Booking_Paid';
    }

    return 'Booking_Unknown';
  }

  isPaidBooking(booking: Reservierung): boolean {
    return booking.status === Reservierungsstatus.VerkauftKasse
    || booking.status === Reservierungsstatus.VerkauftInternet
    || booking.status === Reservierungsstatus.AusgedrucktInternet;
  }

  canBuyBooking(booking: Reservierung): boolean {
    const screening = this.getScreening(booking.vorstellungsId);
    return (screening != null && (screening.reservierungsEinstellung === 'Kauf' || screening.reservierungsEinstellung === 'KaufUndReservierung') && (this.startupService.getConfig().stripe.enabled || this.startupService.getConfig().eps.enabled));
  }

  deleteBooking(booking: Reservierung): void {
    this.viewState = ComponentStatus.Processing;
    this.bookingService.delete(booking.id).subscribe(res => {
      console.log(res);
      this.loadData();
    });
  }

  showBarcode(booking: Reservierung): void {
    this.dialogService.showBarcodeDialog(booking)
    .then();
  }

  buyTicket(booking: Reservierung): void {
    this.router.navigate(['/booking/' + booking.id]);
  }

  showQRCode(booking: Reservierung): void {
    this.dialogService.showQRCodeDialog(booking)
    .then();
  }

  downloadTicket(booking: Reservierung): void {
    this.bookingService.downloadTicket(booking.id)
    .subscribe(res => {
      importedSaveAs(res, booking.pdfName);
    });
  }

  downloadInvoice(booking: Reservierung): void {
    this.bookingService.downloadInvoice(booking.id)
    .subscribe(res => {
      importedSaveAs(res, 'Rechnung.pdf');
    });
  }

  get isTicketDownloadAllowed(): boolean {
    return this.startupService.getConfig().allowTicketDownload;
  }

  get isCancellationAllowed(): boolean {
    return this.startupService.getConfig().bookingCancellationAllowed;
  }

  get isInvoiceDownloadAllowed(): boolean {
    return this.startupService.getConfig().allowInvoiceDownload;
  }

  get isUsingBlockLogic(): boolean {
    return this.startupService.getConfig().useBlockLogic;
  }

  public isSeating(booking: Reservierung): boolean {
    const screening = this.getScreening(booking.vorstellungsId);
    console.log(`Screening found: ${screening != null}`);
    console.log(`isSeating: ${screening != null && screening.saalArt === SaalArt.Sitzplaetze}`);
    return screening != null && screening.saalArt === SaalArt.Sitzplaetze;
  }
}
