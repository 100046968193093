import { Component, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { ViewBaseComponent } from '../../misc/view-base/view-base.component';
import { Vorstellung } from '../../../models/vorstellung';
import { ComponentStatus } from '../../../models/enums/componentStatus';
import { ScreeningService } from '../../../services/screening.service';
import { L10nTranslationService } from 'angular-l10n';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { StartupService } from '../../../services/startup.service';

@Component({
  selector: 'app-block-info',
  templateUrl: './block-info.component.html'
})
export class BlockInfoComponent extends ViewBaseComponent implements OnInit, AfterViewChecked {

  private _screenings: Vorstellung[] = [];
  private _screeningId: string;

  private _coordsFrauenschuh: number[] = [];
  private _coordsAlpenrose: number[] = [];
  private _coordsEdelweiss: number[] = [];
  private _coordsAdlerhorst: number[] = [];
  private _lastWidth = -1;

  @ViewChild('imageSitzplan', {static: false}) image;


  constructor(private screeningService: ScreeningService, private translation: L10nTranslationService, private route: ActivatedRoute, startupService: StartupService, titleService: Title, meta: Meta) {
    super(titleService, startupService, meta);
   }

  ngOnInit() {
    this.viewState = ComponentStatus.Loading;

    this.route.paramMap.subscribe(map => {
      const screeningId = map.get('id');
      if (screeningId == null) {
        this.errorText = this.translation.translate('ScreeningInfo_Error');
        return;
      }
      this.screeningId = screeningId;

      this.screeningService.getScreeningsForBlock(screeningId)
      .subscribe(x => {
        this.screenings = x;
      });
    });
  }

  ngAfterViewChecked(): void {
    setTimeout(() => this.updateCoords(this.image.nativeElement.width), 50);
  }

  get screeningId(): string {
    return this._screeningId;
  }

  set screeningId(value: string) {
    this._screeningId = value;
  }

  get screenings(): Vorstellung[] {
    return this._screenings;
  }

  set screenings(value: Vorstellung[]) {
    this._screenings = value;
  }

  getScreeningIdForBlock(blockName: string): string {
    let id: string = null;
    for (const screening of this.screenings) {
      if (screening.saalName === blockName) {
        id = screening.vorstellungsId;
        break;
      }
    }
    return id;
  }

  get coordsFrauenschuh(): number[] {
    return this._coordsFrauenschuh;
  }

  get coordsAlpenrose(): number[] {
    return this._coordsAlpenrose;
  }

  get coordsEdelweiss(): number[] {
    return this._coordsEdelweiss;
  }

  get coordsAdlerhorst(): number[] {
    return this._coordsAdlerhorst;
  }

  onResize(event) {
    this.updateCoords(this.image.nativeElement.width);
  }

  private updateCoords(width: number): void {
    if (this._lastWidth === width) {
      return;
    }
    this._lastWidth = width;

    const coordsAlpenrose: number[] = [77, 465, 143, 215, 405, 250, 561, 416, 542, 481, 480, 512];
    const coordsFrauenschuh: number[] = [566, 405, 683, 387, 654, 154, 575, 144, 416, 161, 428, 254];
    const coordsEdelweiss: number[] = [668, 151, 757, 140, 867, 141, 971, 204, 854, 367, 698, 388];
    const coordsAdlerhorst: number[] = [117, 41, 578, 40, 649, 110, 181, 105];
    const coordsAlpenroseCalculated: number[] = [];
    const coordsFrauenschuhCalculated: number[] = [];
    const coordsEdelweissCalculated: number[] = [];
    const coordsAdlerhorstCalculated: number[] = [];

    const percent = width / 1000;
    for (const coord of coordsAlpenrose) {
      coordsAlpenroseCalculated.push(coord * percent);
    }
    for (const coord of coordsFrauenschuh) {
      coordsFrauenschuhCalculated.push(coord * percent);
    }
    for (const coord of coordsEdelweiss) {
      coordsEdelweissCalculated.push(coord * percent);
    }
    for (const coord of coordsAdlerhorst) {
      coordsAdlerhorstCalculated.push(coord * percent);
    }
    this._coordsAdlerhorst = coordsAdlerhorstCalculated;
    this._coordsAlpenrose = coordsAlpenroseCalculated;
    this._coordsEdelweiss = coordsEdelweissCalculated;
    this._coordsFrauenschuh = coordsFrauenschuhCalculated;
  }
}
