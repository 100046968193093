import { Globals } from './../models/globals';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { DataService } from './storage.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationService {
    private baseUrl: string;

    constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient, private globals: Globals,
    private storageService: DataService) {
      this.baseUrl = baseUrl;
    }

    login(eMailAddress: string, password: string): Observable<User | null> {
        return this.http.post<User>(this.baseUrl + 'api/User/Authenticate', { eMailAddress: eMailAddress, password: password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                  this.storageService.token = user.token;
                    this.globals.setUser(user);
                    return user;
                }

                return null;
            }));
    }

    logout(deleteStorage: boolean): void {
      this.globals.removeUser();
      if (deleteStorage) {
        this.storageService.removeToken();
      }
    }
}
