import { PreisSchema } from './../models/preisSchema';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';

@Injectable()
export class PricingService {
  private baseUrl: string;

  constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient) {
    this.baseUrl = baseUrl;
  }

  getById(id: string): Observable<PreisSchema> {
    return this.http.get<PreisSchema>(this.baseUrl + 'api/Pricing/Get/' + id);
  }

  delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + 'api/Pricing/Delete/' + id);
  }

  create(template: PreisSchema): Observable<PreisSchema> {
    return  this.http.post<PreisSchema>(this.baseUrl + 'api/Pricing/Create', template);
  }

  replace(template: PreisSchema): Observable<boolean> {
    return  this.http.put<boolean>(this.baseUrl + 'api/Pricing/Create', template);
  }

  getByScreeningId(screeningId: string): Observable<PreisSchema> {
    return this.http.get<PreisSchema>(this.baseUrl + 'api/Pricing/ByScreeningId/' + screeningId);
  }
}
