export enum UserRight {
  // tslint:disable-next-line:no-bitwise
  None = 0,
  // tslint:disable-next-line:no-bitwise
  Activated = 1 << 0,
  // tslint:disable-next-line:no-bitwise
  Banned = 1 << 1,
  // tslint:disable-next-line:no-bitwise
  Booking = 1 << 2,
  // tslint:disable-next-line:no-bitwise
  Mitarbeiter = 1 << 3,
  // tslint:disable-next-line:no-bitwise
  Admin = 1 << 4,
  // tslint:disable-next-line:no-bitwise
  Kinoprogramm = 1 << 5
}
