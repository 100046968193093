import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class DataService {
  private tokenKey = 'session_token';

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) { }

  get token(): string {
    if (this.hasToken()) {
      return this.storage.get(this.tokenKey);
    }
  }

  set token(userId: string) {
    this.storage.set(this.tokenKey, userId);
  }

  removeToken(): void {
    this.storage.remove(this.tokenKey);
  }

  hasToken(): boolean {
    return this.storage.has(this.tokenKey);
  }
}
