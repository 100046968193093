import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EpsCallback } from '../models/epsCallback';

@Injectable()
export class EPSService {
  private baseUrl: string;

  constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient) {
    this.baseUrl = baseUrl;
  }

  public initiateTransfer(bookingId: string): Observable<EpsCallback> {
    return this.http.get<EpsCallback>(this.baseUrl + 'api/EPS/InitiateTransfer/' + bookingId);
  }
}
