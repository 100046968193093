import { AuthenticationService } from './../../../services/authentication.service';
import { Component, OnInit, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { User } from '../../../models/user';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewBaseComponent } from '../../misc/view-base/view-base.component';
import { ComponentStatus } from '../../../models/enums/componentStatus';
import { L10nTranslationService } from 'angular-l10n';
import { Router } from '@angular/router';
import { StartupService } from '../../../services/startup.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html'
})
export class LoginDialogComponent extends ViewBaseComponent implements OnInit {
  @Input()
  authService: AuthenticationService;
  @Output()
  user: User;

  loginForm = new UntypedFormGroup({
    emailAddress: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.maxLength(50)
    ]),
    password: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(50)
    ])
  });

  constructor(public activeModal: NgbActiveModal, private translation: L10nTranslationService, private router: Router, startupService: StartupService, titleService: Title, meta: Meta) {
    super(titleService, startupService, meta);
  }

  ngOnInit() {
    this.viewState = ComponentStatus.Displaying;

    this.loginForm.get('emailAddress').valueChanges.subscribe(val => {
      let mail = <string>val;
      if (!mail != null && (mail.endsWith(' ') || mail.startsWith(' '))) {
        mail = mail.trim();
        this.loginForm.get('emailAddress').setValue(mail);
      }
    });
  }

  onSubmit(): void {
    if (!this.loginForm.valid) {
      return;
    }

    this.viewState = ComponentStatus.Processing;
    this.authService
      .login(
        this.loginForm.controls['emailAddress'].value,
        this.loginForm.controls['password'].value
      )
      .subscribe(user => {
        if (user != null) {
          this.user = user;
          this.activeModal.close('done');
        } else {
          this.errorText = this.translation.translate('Login_AuthError');
        }
      });
  }

  close(): void {
    this.activeModal.close('closed');
  }
}
