import { Component, OnInit } from '@angular/core';
import { ViewBaseComponent } from '../../misc/view-base/view-base.component';
import { ComponentStatus } from '../../../models/enums/componentStatus';
import { Validators, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { L10nTranslationService } from 'angular-l10n';
import { RecoveryService } from '../../../services/recovery.service';
import { Meta, Title } from '@angular/platform-browser';
import { StartupService } from '../../../services/startup.service';

@Component({
  selector: 'app-recover-start',
  templateUrl: './recover-start.component.html'
})
export class RecoverStartComponent extends ViewBaseComponent implements OnInit {
  isSuccess = false;

  recoveryForm = new UntypedFormGroup({
    emailAddress: new UntypedFormControl('', [Validators.required, Validators.email, Validators.maxLength(50)])
  });

  constructor(private recoveryService: RecoveryService, private translation: L10nTranslationService, startupService: StartupService, titleService: Title, meta: Meta) {
    super(titleService, startupService, meta);
  }

  ngOnInit() {
    this.viewState = ComponentStatus.Displaying;
  }

  onSubmit(): void {
    this.viewState = ComponentStatus.Processing;
    if (this.recoveryForm.valid) {
      this.recoveryService.startRecovery(this.recoveryForm.get('emailAddress').value)
      .then(res => {
        if (res) {
          this.isSuccess = true;
          this.viewState = ComponentStatus.Displaying;
        } else {
          this.errorText = this.translation.translate('RecoveryStart_Error');
        }
      });
    }
  }
}
