import { Component, OnInit, Input, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewBaseComponent } from '../../misc/view-base/view-base.component';
import { ComponentStatus } from '../../../models/enums/componentStatus';
import { Reservierung } from '../../../models/reservierung';
import { StartupService } from '../../../services/startup.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-qrcode-dialog',
  templateUrl: './qrcode-dialog.component.html'
})
export class QRCodeDialogComponent extends ViewBaseComponent implements OnInit {
  @Input()
  booking: Reservierung;

  private baseUrl: string;

  constructor(@Inject('BASE_URL') baseUrl: string, public activeModal: NgbActiveModal, startupService: StartupService, titleService: Title, meta: Meta) {
    super(titleService, startupService, meta);
    this.baseUrl = baseUrl;
  }

  ngOnInit() {
    this.viewState = ComponentStatus.Displaying;
  }

  getQRCodeUrl(): string {
    if (this.booking != null) {
      return this.baseUrl + 'api/Booking/QRCode/' + this.booking.id;
    }
  }
}
