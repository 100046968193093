import { AbstractControl, ValidatorFn, UntypedFormGroup } from '@angular/forms';

export function passwordsEqualValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const group = <UntypedFormGroup>control;
    const password1 = <string>group.controls['password1'].value;
    const password2 = <string>group.controls['password2'].value;

    return password1 !== password2 ? {'passwordsEqual': {value: (password1 + '/' + password2)}} : null;
  };
}

export function passwordsEqualValidatorIfRequired(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const group = <UntypedFormGroup>control;
    const password1 = <string>group.get('password1').value;
    const password2 = <string>group.get('password2').value;
    const isRequired = <boolean>group.get('changePassword').value;

    return (password1 !== password2 || password1.length === 0 || password2.length === 0)
    && isRequired ? {'passwordsEqual': {value: (password1 + '/' + password2)}} : null;
  };
}
