import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Config } from '../models/config';

@Injectable()
export class StartupService {
  private config: Config;
  private baseUrl: string;

    constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient) {
      this.baseUrl = baseUrl;
    }

    // This is the method you want to call at bootstrap
    // Important: It should return a Promise
    load(): Promise<any> {
        return this.http.get<Config>(this.baseUrl + 'assets/config/config.json').toPromise().then(conf => this.config = conf);
    }

    getConfig(): Config {
        return this.config;
    }

    isBeforeCoronaEndDate(date: Date): boolean {
      const coronaEndDate = Date.parse(this.config.coronaEndDate);
      return date.getTime() < coronaEndDate;
    }
}
