import { Component, OnChanges, SimpleChanges, Input, Output, EventEmitter, NgZone, HostListener } from '@angular/core';
import { Vorstellung } from '../../../models/vorstellung';
import { PreisSchema } from '../../../models/preisSchema';
import { Sitz } from '../../../models/sitz';
import { SitzTyp } from '../../../models/enums/sitzTyp';
import { L10nTranslationService } from 'angular-l10n';
import { Reservierung } from '../../../models/reservierung';
import { StartupService } from '../../../services/startup.service';
import { Kategorie } from '../../../models/kategorie';
import { Reservierungsstatus } from '../../../models/enums/reservierungsstatus';
import Konva from 'konva';


@Component({
  selector: 'app-seating-plan',
  templateUrl: './seating-plan.component.html'
})
export class SeatingPlanComponent implements OnChanges {
  @Input()
  screening: Vorstellung;

  @Input()
  pricing: PreisSchema;

  @Input()
  booking: Reservierung;

  @Input()
  bookings: Reservierung[] = [];

  @Input()
  selectedSeats: Sitz[] = [];

  @Output()
  seatSelectedChanged = new EventEmitter<[number, number]>();

  // konva stage object
  _stage: Konva.Stage = null;

  // internal values
  canvasWidthMax = 750;
  canvasHeightMax = 500;
  legendWidth = 200;
  legendHeight = 200;
  _canvasWidth = 10; // never go below, iOS ...
  _canvasHeight = 10; // never go below, iOS ...
  seatingWidth = 0;

  tileWidthMax = 30;
  tileHeightMax = 40;
  seatSizeMax = 30;
  fontSizeMax = 16;

  seatSize = this.seatSizeMax;
  fontSize = this.fontSizeMax;
  fontSizeHeader = this.fontSizeMax + 4;
  tileWidth = this.tileWidthMax;
  tileHeight = this.tileHeightMax;
  lineHeight = 25;
  legendTileWidth = 20;
  fontSizeLegend = 15;
  fontSizeLegendHeader = 20;
  tileWidthLegend = 20;

  spacerColumnsLeft = 2;
  spacerColumnsRight = 1;
  spacerRowsTop = 2;
  spacerRowsBottom = 0;

  // helpers to determine if legend should be drawn
  showLegend: boolean;

  constructor(private startupService: StartupService,
    private translation: L10nTranslationService, private ngZone: NgZone) { }

    get canvasWidth(): number {
      return this._canvasWidth;
    }

    set canvasWidth(value: number) {
      if (value <= 0) {
        this._canvasWidth = 10;
      } else if (value !== this._canvasWidth) {
        this._canvasWidth = value;
      }
    }

    get canvasHeight(): number {
      return this._canvasHeight;
    }

    set canvasHeight(value: number) {
      if (value <= 0) {
        this._canvasHeight = 10;
      } else if (value !== this._canvasHeight) {
        this._canvasHeight = value;
      }
    }

  @HostListener('window:resize', ['$event'])
  onSizeChanged(event) {
    if (this.screening != null && this.pricing != null && this.selectedSeats != null && this.booking != null) {
      this.drawSeats();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.screening != null && this.pricing != null && this.selectedSeats != null && this.booking != null) {
      this.drawSeats();
    }
  }

  onSeatClicked(event: any): void {
    // the callback is not within the angular zone, so dispatch call into it
    if (event.target != null) {
      const clickedX = <number>event.target.attrs.x;
      const clickedY = <number>event.target.attrs.y;
      const comp = <SeatingPlanComponent>event.currentTarget.attrs.component;

      const stepWidth = comp.tileWidth;
      const stepHeight = comp.tileHeight;

      const x = Math.floor((clickedX / stepWidth) - comp.spacerColumnsLeft);
      const y = Math.floor((clickedY / stepHeight) - comp.spacerRowsTop);

      if (x < 0 || y < 0) {
        return;
      }
      comp.seatSelectedChanged.emit([x, y]);
    }
  }

  /*
   * Draw all seats
   */
  private drawSeats(): void {
    if (this.screening != null && this.pricing != null) {
      // resize canvas to size of the hall
      this.updateCanvasSize();

      // create layers
      const staticLayer = new Konva.Layer();
      const seatsLayer = new Konva.Layer();

      // draw screen ("leinwand") at the top
      if (this.startupService.getConfig().drawScreen) {
        this.drawScreen(staticLayer);
      }

      // draw legend
      this.drawLegend(staticLayer);

      // draw seats, except placeholders
      for (const seat of this.screening.sitze) {
        this.drawSeat(seat, seatsLayer);
      }

      // add layers
      this._stage.add(seatsLayer);
      this._stage.add(staticLayer);

      // render hall
      this._stage.draw();
    }
  }

  /*
   * Draws the screen on top of the image
   */
  private drawScreen(layer: Konva.Layer): void {
    // calculate basic corner points of the screen
    const lineWidth = Math.floor(this.seatingWidth * 0.95);
    const x = Math.floor((this.seatingWidth - lineWidth) / 2);
    const y = Math.floor(this.tileHeight / 4);
    const width = lineWidth;
    const height = y - Math.floor(y * 1.5);

    const rect = new Konva.Rect({
      x: y,
      y: y,
      width: width,
      height: height,
      fill: 'transparent',
      stroke: 'black',
      strokeWidth: 2
    });
    layer.add(rect);

    // draw screen label
    const screenLabel = this.createText(this.translation.translate('ScreeningInfo_Screen'), x + (width / 2) - (this.tileWidth * 1.25), y * 2, false);
    layer.add(screenLabel);
  }

  /*
   * Draws a single seat
   */
  private drawSeat(seat: Sitz, layer: Konva.Layer): void {
    // get seat color and if it is already booked
    const seatColor = this.selectSeatColor(seat);

    const startX = Math.floor((seat.x + this.spacerColumnsLeft) * this.tileWidth);
    const endX = Math.floor(startX + this.tileWidth);

    const startY = Math.floor((seat.y + this.spacerRowsTop) * this.tileHeight);
    const endY = Math.floor(startY + this.seatSize);
    const seatSize = Math.floor(this.seatSize);
    const tileWidth = Math.floor(this.tileWidth);

    // row number label if first seat in row
    if (seat.x === 1 && seat.reihe !== 0) {
      const rowText = '' + seat.reihe;
      const rowMarker = this.createText(rowText, startX - this.tileWidth, startY, false);
      layer.add(rowMarker);
    }

    // stop if placeholder
    if (seat.typ === SitzTyp.Platzhalter) {
      return;
    }

    // seat box
    const seatBox = this.createRect(startX, startY, seatSize, seatSize, seatColor, 1.0);
    layer.add(seatBox);

    // category bar
    const category = this.getCategory(seat);
    if (category != null) {
      const categoryBox = this.createRect(startX, startY + (seatSize / 1.5), tileWidth, seatSize / 3, category.farbe, 0.5);
      layer.add(categoryBox);
    }

    // draw seat border as poly
    let offsetYLeft = 0;
    if (seat.typ === SitzTyp.PartnerRechts) {
      offsetYLeft += this.seatSize / 2;
    }
    let offsetYRight = 0;
    if (seat.typ === SitzTyp.PartnerLinks) {
      offsetYRight += this.seatSize / 2;
    }

    const border = this.createLine([startX + 0.5, startY + 0.5 + offsetYLeft, startX + 0.5, endY + 0.5, endX + 0.5, endY + 0.5, endX + 0.5, startY + 0.5 + offsetYRight], 'black');
    layer.add(border);
  }

  /*
   * Draw legend
  */
  private drawLegend(layer: Konva.Layer): void {

    if (this.showLegend) {
      // header
     const header = this.createTextLegend('Legende', this.seatingWidth, 0, true);
     layer.add(header);

      // first: seating legend
      this.drawSeatingLegend(layer);

      // then draw pricing categories
      this.drawCategoriesLegend(layer);
    }
  }

  /*
   * Draw legend for seating
   */
  private drawSeatingLegend(layer: Konva.Layer): void {
    const showSmoker = this.startupService.getConfig().showSmokerLegend && this.screening.rauchervorstellung;
    const xCol0 = this.seatingWidth;
    const xCol1 = +xCol0 + (showSmoker ? 150 : 100);
    let y = this.lineHeight + 10;

    // free non-smoker
    const freeNonLabel = showSmoker ? this.translation.translate('ScreeningInfo_LegendFreeNonSmoker') : this.translation.translate('ScreeningInfo_LegendFree');
    const freeNonSmokerSeat = this.createRect(xCol1, y, this.tileWidthLegend, this.tileWidthLegend, '#a4ffa2', 1);
    const freeNonSmokerBorder = this.createLine([xCol1 + 0.5, y + 0.5, xCol1 + 0.5, y + 0.5 + this.tileWidthLegend, xCol1 + 0.5 + this.tileWidthLegend, y + 0.5 + this.tileWidthLegend, xCol1 + 0.5 + this.tileWidthLegend, y + 0.5], 'black');
    const freeNonText = this.createTextLegend(freeNonLabel, xCol0, y, false);
    layer.add(freeNonSmokerSeat);
    layer.add(freeNonSmokerBorder);
    layer.add(freeNonText);

    // next line
    y += this.lineHeight;

    // free smoker
    if (showSmoker) {
      const freeSmokerSeat = this.createRect(xCol1, y, this.tileWidthLegend, this.tileWidthLegend, '#99CC99', 1);
      const freeSmokerBorder = this.createLine([xCol1 + 0.5, y + 0.5, xCol1 + 0.5, y + 0.5 + this.tileWidthLegend, xCol1 + 0.5 + this.tileWidthLegend, y + 0.5 + this.tileWidthLegend, xCol1 + 0.5 + this.tileWidthLegend, y + 0.5], 'black');
      const freeText = this.createTextLegend(this.translation.translate('ScreeningInfo_LegendFreeSmoker') , xCol0, y, false);
      layer.add(freeSmokerSeat);
      layer.add(freeSmokerBorder);
      layer.add(freeText);

      // next line
      y += this.lineHeight;
    }

    // occupied
    const occupiedText = this.createTextLegend(this.translation.translate('ScreeningInfo_LegendOccupied') , xCol0, y, false);
    const occupiedSeat = this.createRect(xCol1, y, this.tileWidthLegend, this.tileWidthLegend, '#ff977b', 1);
    const occupiedBorder = this.createLine([xCol1 + 0.5, y + 0.5, xCol1 + 0.5, y + 0.5 + this.tileWidthLegend, xCol1 + 0.5 + this.tileWidthLegend, y + 0.5 + this.tileWidthLegend, xCol1 + 0.5 + this.tileWidthLegend, y + 0.5], 'black');
    layer.add(occupiedText);
    layer.add(occupiedSeat);
    layer.add(occupiedBorder);

    // next line
    y += this.lineHeight;

    // blocked
    const blockedText = this.createTextLegend(this.translation.translate('ScreeningInfo_LegendBlocked') , xCol0, y, false);
    const blockedSeat = this.createRect(xCol1, y, this.tileWidthLegend, this.tileWidthLegend, '#ABB2B9', 1);
    const blockedBorder = this.createLine([xCol1 + 0.5, y + 0.5, xCol1 + 0.5, y + 0.5 + this.tileWidthLegend, xCol1 + 0.5 + this.tileWidthLegend, y + 0.5 + this.tileWidthLegend, xCol1 + 0.5 + this.tileWidthLegend, y + 0.5], 'black');
    layer.add(blockedText);
    layer.add(blockedSeat);
    layer.add(blockedBorder);

    // next line
    y += this.lineHeight;

    // selected
    const selectedText = this.createTextLegend(this.translation.translate('ScreeningInfo_LegendSelected') , xCol0, y, false);
    const selectedSeat = this.createRect(xCol1, y, this.tileWidthLegend, this.tileWidthLegend, '#00a9ff', 1);
    const selectedBorder = this.createLine([xCol1 + 0.5, y + 0.5, xCol1 + 0.5, y + 0.5 + this.tileWidthLegend, xCol1 + 0.5 + this.tileWidthLegend, y + 0.5 + this.tileWidthLegend, xCol1 + 0.5 + this.tileWidthLegend, y + 0.5], 'black');
    layer.add(selectedSeat);
    layer.add(selectedText);
    layer.add(selectedBorder);

    // next line
    y += this.lineHeight;

    // normal seat
    const normalText = this.createTextLegend(this.translation.translate('ScreeningInfo_LegendNormal') , xCol0, y, false);
    const normalSeat = this.createRect(xCol1, y, this.tileWidthLegend, this.tileWidthLegend, '#a4ffa2', 1);
    const normalBorder = this.createLine([xCol1 + 0.5, y + 0.5, xCol1 + 0.5, y + 0.5 + this.tileWidthLegend, xCol1 + 0.5 + this.tileWidthLegend, y + 0.5 + this.tileWidthLegend, xCol1 + 0.5 + this.tileWidthLegend, y + 0.5], 'black');
    layer.add(normalText);
    layer.add(normalSeat);
    layer.add(normalBorder);

    // next line
    y += this.lineHeight;

    // partner seat
    if (!this.startupService.getConfig().useBlockLogic) {
        const partnerText = this.createTextLegend(this.translation.translate('ScreeningInfo_LegendPartner') , xCol0, y, false);
        const partnerLeftSeat = this.createRect(xCol1, y, this.tileWidthLegend, this.tileWidthLegend, '#a4ffa2', 1);
        const partnerLeftBorder = this.createLine([xCol1 + 0.5, y + 0.5, xCol1 + 0.5, y + 0.5 + this.tileWidthLegend, xCol1 + 0.5 + this.tileWidthLegend, y + 0.5 + this.tileWidthLegend, xCol1 + 0.5 + this.tileWidthLegend, y + 0.5 + (this.tileWidthLegend / 2)], 'black');
        const partnerRightSeat = this.createRect(xCol1 + this.tileWidthLegend, y, this.tileWidthLegend, this.tileWidthLegend, '#a4ffa2', 1);
        const partnerRightBorder = this.createLine([xCol1 + 0.5 + this.tileWidthLegend, y + 0.5 + (this.tileWidthLegend / 2), xCol1 + this.tileWidthLegend + 0.5, y + 0.5 + this.tileWidthLegend, xCol1 + this.tileWidthLegend + 0.5 + this.tileWidthLegend, y + 0.5 + this.tileWidthLegend, xCol1 + this.tileWidthLegend + 0.5 + this.tileWidthLegend, y + 0.5], 'black');

        layer.add(partnerText);
        layer.add(partnerLeftSeat);
        layer.add(partnerLeftBorder);
        layer.add(partnerRightSeat);
        layer.add(partnerRightBorder);
    }
  }

  /*
   * Calculates canvas size based on hall size (number of rows and columns)
   */
  private updateCanvasSize(): void {
    // set all values to max
    this.tileHeight = this.tileHeightMax;
    this.tileWidth = this.tileWidthMax;
    this.seatSize = this.seatSizeMax;
    this.fontSize = this.fontSizeMax;

    const screenWidth = screen.width;
    this.showLegend = screenWidth > 750;

    // get width of window and adjust maximum width accordingly
    this.canvasWidthMax = this.showLegend ? window.innerWidth - this.legendWidth - 50 : window.innerWidth - 125;
    if (this.canvasWidthMax > 750) {
      this.canvasWidthMax = 750;
    } else if (this.canvasWidthMax < 150) {
      this.canvasWidthMax = 150;
    }

    // if no screening available, just shortcut out of here
    if (this.screening == null) {
      this.canvasWidth = this.canvasWidthMax;
      this.canvasHeight = this.canvasHeightMax;
      return;
    }

    // store data in here
    let canvasWidthResult = this.canvasWidth;
    let canvasHeightResult = this.canvasHeight;

    // calculate maximum number of seats on x and y => find out number of rows and columns
    let xMax = 0;
    let yMax = 0;

    for (const sitz of this.screening.sitze) {
      if (sitz.x > xMax) {
        xMax = sitz.x;
      }
      if (sitz.y > yMax) {
        yMax = sitz.y;
      }
    }

    // add spacer rows for bottom and top (i.e. space for screen)
    const rows = yMax + this.spacerRowsBottom + this.spacerRowsTop + 1;

    // +3 because 0-9 = 10, and we need 1 spacer on the left and one on the right
    const columns = xMax + this.spacerColumnsLeft + this.spacerColumnsRight + 1;

    // calculate canvas size
    canvasWidthResult = columns * this.tileWidth;
    canvasHeightResult = rows * this.tileHeight;

    // if canvas too high => resize down to max height and resize width accordingly
    if (canvasHeightResult > this.canvasHeightMax) {
      canvasHeightResult = this.canvasHeightMax;
      this.tileHeight = Math.floor(this.canvasHeightMax / rows);

      // calculate percentage of original size to adapt width
      const sizePerc = this.tileHeight / this.tileHeightMax;

      // calculate new tile sizes
      this.tileWidth = Math.floor(this.tileWidthMax * sizePerc);
      this.seatSize = Math.floor(this.seatSizeMax * sizePerc);
      this.fontSize = Math.floor(this.fontSizeMax * sizePerc);

      // re-calc width
      canvasWidthResult = columns * this.tileWidth;
    }

    // if canvas too wide => resize down to max width and resize height accordingly

    if (canvasWidthResult > this.canvasWidthMax) {
      canvasWidthResult = this.canvasWidthMax;
      this.tileWidth = Math.floor(this.canvasWidthMax / columns);

      // calculate percentage of original size to adapt height
      const sizePerc = this.tileWidth / this.tileWidthMax;

      // calculate new tile sizes
      this.tileHeight = Math.floor(this.tileHeightMax * sizePerc);
      this.seatSize = Math.floor(this.seatSizeMax * sizePerc);
      this.fontSize = Math.floor(this.fontSizeMax * sizePerc);

      // re-calc height
      canvasHeightResult = rows * this.tileHeight;
    }

    // now add width for legend and if height is not enough increase to minimum for legend
    this.seatingWidth = canvasWidthResult; // store seating width for calculation on where the legend starts

    if (this.showLegend) {
      canvasWidthResult += this.legendWidth;
      if (canvasHeightResult < this.legendHeight) {
        canvasHeightResult = this.legendHeight;
      }
    }

    this.canvasWidth = canvasWidthResult;
    this.canvasHeight = canvasHeightResult;

    // now create stage
    this._stage = new Konva.Stage({
      container: 'seatingPlanContainer',
      width: this.canvasWidth,
      height: this.canvasHeight
    });
    this._stage.setAttr('component', this);
    this._stage.on('click touchend', this.onSeatClicked);
  }

  /*
   * Draws the categories in the legend
   */
  private drawCategoriesLegend(layer: Konva.Layer): void {
    // do not draw for Geierwally in 2021
    // TODO check again in 2022
    console.log(this.screening.vorstellungszeit.getFullYear());
    if (this.startupService.getConfig().useBlockLogic && this.screening.vorstellungszeit.getFullYear() === 2021) {
        return;
    }


    // calc starting positions
    let y = (this.lineHeight * 7) + 10;
    const showSmoker = this.startupService.getConfig().showSmokerLegend;
    const xCol0 = this.seatingWidth;
    const xCol1 = +xCol0 + (showSmoker ? 150 : 100);

    // draw all legends
    for (const scheme of this.pricing.kategorien) {
      const isCategoryUsed = this.isCategoryUsed(scheme);
      if (isCategoryUsed && scheme.name !== '') {
        // seat box is background so that color is exactly the same as in the seating plan
        const text = this.createTextLegend(scheme.name, xCol0, y, false);
        const seatBox = this.createRect(xCol1, y + (this.tileWidthLegend * 0.3), this.tileWidthLegend, this.tileWidthLegend / 4, '#a4ffa2', 1.0);
        const catBox = this.createRect(xCol1, y + (this.tileWidthLegend * 0.3), this.tileWidthLegend, this.tileWidthLegend / 4, scheme.farbe, 0.5);
        layer.add(seatBox);
        layer.add(catBox);
        layer.add(text);

        y += this.lineHeight;
      }
    }
  }

  private isCategoryUsed(category: Kategorie): boolean {
    for (const seat of this.screening.sitze) {
      if (seat.kategorieId === category.id) {
        return true;
      }
    }
    return false;
  }

  private createRect(
    left: number,
    top: number,
    width: number,
    height: number,
    color: string,
    opacity: number
  ): Konva.Rect {
    const rect = new Konva.Rect({
      x: left,
      y: top,
      fill: color,
      width: width,
      height: height,
      strokeWidth: 1,
      opacity: opacity
    });

    return rect;
  }

  /*
   * Fabric line requires coordinates as an array with 4 values, x,y start and x,y end
   * i.e. [10, 15, 27, 27] -> line from 10/15 to 27/27
   */
  private createLine(coords: number[], color: string): Konva.Line {
    const line = new Konva.Line({
      points: coords,
      stroke: color,
      strokeWidth: 1,
      shadowForStrokeEnabled: false,
      shadowEnabled: false,
      opacity: 1,
      lineCap: 'butt'
    });
    return line;
  }

  private createText(content: string, left: number, top: number, isHeader: boolean): Konva.Text {
    const fontSize = isHeader ? this.fontSizeHeader : this.fontSize;
    const text = new Konva.Text({
      x: left,
      y: top,
      text: content,
      fontSize: fontSize,
      fontFamily: 'Arial',
      fill: 'black'
    });
    return text;
  }

  private createTextLegend(content: string, left: number, top: number, isHeader: boolean): Konva.Text {
    const fontSize = isHeader ? this.fontSizeHeader : this.fontSize;
    const text = new Konva.Text({
      x: left,
      y: top,
      text: content,
      fontSize: fontSize,
      fontFamily: 'Arial',
      fill: 'black'
    });
    return text;
  }

  private getCategory(seat: Sitz) {
    if (this.pricing != null) {
      for (const cat of this.pricing.kategorien) {
        if (cat.id === seat.kategorieId) {
          return cat;
        }
      }
    }

    return null;
  }

  private isSeatSelected(seat: Sitz): boolean {
    const index = this.selectedSeats.indexOf(seat);
    return index > -1;
  }

  private isSeatAlreadyBooked(seat: Sitz): boolean {
    const index = this.booking.sitzIds.indexOf(seat.id);
    return index > -1;
  }

  private selectSeatColor(seat: Sitz): string {
    if (this.bookings != null && this.bookings.length > 0) {
      // get booking for seat
      const book = this.getBookingForSeat(seat);
      if (book != null) {
        // not determine color based in real state
        if (book.status === Reservierungsstatus.Reserviert) {
          return '#fedf69';
        } else if (book.status === Reservierungsstatus.InternetReserviert) {
          return '#00ffff';
        } else if (book.status === Reservierungsstatus.VerkauftKasse) {
          return '#ff6347';
        } else if (book.status === Reservierungsstatus.VerkauftInternet) {
          return '#ff0000';
        } else if (book.status === Reservierungsstatus.Vorgemerkt) {
          return '#ffa500';
        } else if (book.status === Reservierungsstatus.AusgedrucktInternet) {
          return '#8b0000';
        } else if (book.status === Reservierungsstatus.Gesperrt) {
          return '#ABB2B9';
        }
      }
    }

    if (this.isSeatAlreadyBooked(seat)) {
      return '#ff977b';
    } else if (this.isSeatSelected(seat)) {
      return '#00a9ff';
    } else {
      return seat.istRaucher ? '#99CC99' : '#a4ffa2';
    }
  }

  private createSeatTooltip(seat: Sitz): string {
    const book = this.getBookingForSeat(seat);
    let tooltip = '';

    if (book != null) {

      let status = '';
      if (book.status === Reservierungsstatus.Reserviert) {
        status = 'Reserviert';
      } else if (book.status === Reservierungsstatus.InternetReserviert) {
        status = 'InternetReserviert';
      } else if (book.status === Reservierungsstatus.VerkauftKasse) {
        status = 'VerkauftKasse';
      } else if (book.status === Reservierungsstatus.VerkauftInternet) {
        status = 'VerkauftInternet';
      } else if (book.status === Reservierungsstatus.Vorgemerkt) {
        status = 'Vorgemerkt';
      } else if (book.status === Reservierungsstatus.AusgedrucktInternet) {
        status = 'AusgedrucktInternet';
      }

      tooltip = 'Code: ' + book.reservierungsCode + ', ' + status;
    }

    return tooltip;
  }

  private getBookingForSeat(seat: Sitz): Reservierung {
    if (this.bookings != null && this.bookings.length > 0) {
      // get booking for seat
      for (const book of this.bookings) {
        for (const seatId of book.sitzIds) {
          if (seatId === seat.id) {
            return book;
          }
        }
      }
    }

    return null;
  }
}
