import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from '../services/storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private storageService: DataService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.storageService.token;
    if (token != null) {
        const clone = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token
          }
        });
        return next.handle(clone);
    }
    return next.handle(request);
  }
}
