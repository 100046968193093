import { UserService } from './services/user.service';
import { DataService } from './services/storage.service';
import { Component, OnInit } from '@angular/core';
import { Cookie } from '@ngx-toolkit/cookie';
import { Globals } from './models/globals';
import { Title } from '@angular/platform-browser';
import { StartupService } from './services/startup.service';
import { Meta } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';

  @Cookie('cookieConsent')
  public cookieConsent: string;

  // property to override cookie constent for bots/spiders/crawlers
  private _overrideConsent = false;

  constructor(private router: Router, private meta: Meta, private titleService: Title, private globals: Globals, private storageService: DataService, private userService: UserService
    , private startupService: StartupService) {
        
    // add default tags
    meta.addTags([
        { name: 'description', content: this.startupService.getConfig().client + ' - Online-Reservierung' },
        { name: 'author', content: this.startupService.getConfig().client },
        { name: 'keywords', content: this.startupService.getConfig().client + ', Online-Reservierung' },
        { name: 'og:title', content: this.startupService.getConfig().client + ' - Online-Reservierung' },
        { name: 'og:type', content: 'website' }
    ]);

    // set title
    this.titleService.setTitle(this.startupService.getConfig().client + ' - Online-Reservierung');

    // register to route changes
    this.router.events.subscribe(event =>{
        if (event instanceof NavigationStart){
           console.log(event.url)
           this.setDefaultMetaTags();
        }
     })

    // perform re-login
    if (this.storageService.hasToken()) {
      this.globals.setAutoLoginInProgress(true);
      // try to get user
      this.userService.getOwnUser()
        .subscribe(user =>  {
          this.globals.setUser(user);
          this.globals.setAutoLoginInProgress(false);
        }, error => {
            console.log(error);
            this.storageService.removeToken();
            this.globals.setAutoLoginInProgress(false);
        });
    }
  }

  private setDefaultMetaTags() {
    // set default tags
    this.meta.updateTag({ name: 'description', content: this.startupService.getConfig().client + ' - Online-Reservierung' });
    this.meta.updateTag({ name: 'author', content: this.startupService.getConfig().client });
    this.meta.updateTag({ name: 'keywords', content: this.startupService.getConfig().client + ', Online-Reservierung' });
    this.meta.updateTag({ name: 'og:title', content: this.startupService.getConfig().client + ' - Online-Reservierung' });
    this.meta.updateTag({ name: 'og:type', content: 'website' });
  }

  ngOnInit(): void {
    if (this.hasConsent) {
      this.appendGaTrackingCode();
    }

    // check user agent and set consent of user agent is a bot/spider
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('bot') !== -1 || userAgent.indexOf('spider') !== -1 || userAgent.indexOf('crawler') !== -1) {
      this._overrideConsent = true;
    }
  }

  public get hasConsent(): boolean {
    if (this._overrideConsent) {
      return true;
    }

    const res = this.cookieConsent != null && this.cookieConsent !== '';
      return res;
  }

  get isStaticPagesActive(): boolean {
    return this.startupService.getConfig().staticPagesActive;
  }

  get hasConsentMarketingStatistics(): boolean {
    return this.cookieConsent != null && this.cookieConsent !== '' && this.cookieConsent.endsWith('true');
  }

  get imprintUrl(): string {
    return this.startupService.getConfig().clientImprint;
  }

  get showSocialFacebook(): boolean {
      return this.startupService.getConfig().social.facebookUrl.length > 0;
  }

  get showSocialInstagram(): boolean {
      return this.startupService.getConfig().social.instagramUrl.length > 0;
  }

  get showSocialTwitter(): boolean {
      return this.startupService.getConfig().social.twitterUrl.length > 0;
  }

  get socialFacebookUrl(): string {
    return this.startupService.getConfig().social.facebookUrl;
  }

  get socialInstagramUrl(): string {
    return this.startupService.getConfig().social.instagramUrl;
  }

  get socialTwitterUrl(): string {
    return this.startupService.getConfig().social.twitterUrl;
  }

  get showSamsungWarning(): boolean {
    if(navigator.userAgent.match(/SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i)) {
      return true;
    }
    return false;
  }

  private appendGaTrackingCode(): void {
    // try {
    //   const script = document.createElement('script');
    //   script.innerHTML = `
    //     (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    //     (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    //     m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    //     })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

    //     ga('create', 'UA-20058861-1', 'auto');
    //   `;
    //   document.head.appendChild(script);
    // } catch (ex) {
    //  console.error('Error appending google analytics');
    //  console.error(ex);
    // }
  }
}
