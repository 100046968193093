import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SocialMediaLandingPage } from '../../models/socialMediaLandingPage';
import { SocialMediaLandingPageService } from '../../services/socialMediaLandingPage.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html'
})
export class LandingPageComponent implements OnInit {

  constructor(private landingPageService: SocialMediaLandingPageService,
    private route: ActivatedRoute,
    private titleService: Title) { }

  private _page: SocialMediaLandingPage;

  ngOnInit() {
    // load landing page by slug
    this.route.paramMap.subscribe(map => {
        const slug = map.get('slug');
        console.log('Slug: ' + slug);
        if (slug !== null) {
            this.landingPageService.getBySlug(slug).then(x => {
                this._page = x;
                this.titleService.setTitle(x.title);
            });
        }

    });
  }

  get page(): SocialMediaLandingPage { return this._page; }

  get customHtml(): string {
      return this.page ? this.page.content : '';
  }
}
