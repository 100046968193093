export enum Reservierungsstatus {
  Geloescht = 1,
  Reserviert = 2,
  VerkauftKasse = 3,
  InternetReserviert = 4,
  VerkauftInternet = 5,
  Vorgemerkt = 6,
  AusgedrucktInternet = 7,
  Gesperrt = 8,
  GescanntInternet = 9
}
