import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { StartupService } from './startup.service';

@Injectable()
export class AdminService {
  private baseUrl: string;

  constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient, private startupService: StartupService) {
    this.baseUrl = baseUrl;
  }
}
