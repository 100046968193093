import { Vorstellung } from './../../../models/vorstellung';
import { ActivatedRoute, Router } from '@angular/router';
import { StartupService } from './../../../services/startup.service';
import { Globals } from './../../../models/globals';
import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from './../../../services/user.service';
import { UserRight } from './../../../models/enums/userRight';
import { ScreeningService } from './../../../services/screening.service';
import { MovieService } from './../../../services/movie.service';
import { PricingService } from './../../../services/pricing.service';
import { PreisSchema } from './../../../models/preisSchema';
import { Kategorie } from './../../../models/kategorie';
import { Film } from './../../../models/film';
import { Sitz } from '../../../models/sitz';
import { SitzTyp } from '../../../models/enums/sitzTyp';
import { ComponentStatus } from '../../../models/enums/componentStatus';
import { Reservierung } from '../../../models/reservierung';
import { BookingService } from '../../../services/booking.service';
import { DialogService } from '../../../services/dialog-service.service';
import { timer, forkJoin } from 'rxjs';
import { ViewBaseComponent } from '../../misc/view-base/view-base.component';
import { L10nTranslationService } from 'angular-l10n';
import { DOCUMENT } from '@angular/common';
import { Reservierungsstatus } from '../../../models/enums/reservierungsstatus';
import { Meta, Title } from '@angular/platform-browser';
import { SaalArt } from '../../../models/enums/saalArt';

@Component({
  selector: 'app-screening-info',
  templateUrl: './screening-info.component.html'
})
export class ScreeningInfoComponent extends ViewBaseComponent implements OnInit {
  screening: Vorstellung;
  pricing: PreisSchema;
  movie: Film;

  booking: Reservierung; // contains already booked seats
  bookings: Reservierung[] = []; // list of bookings if you are an admin
  doubleBookedSeats: Sitz[] = []; // list of double booked seats

  maxSeatsInBooking; number;
  maxSeatsInBookingList: number[];
  maxSeatsChildrenList: number[];
  private _bookingName: string;
  private _bookingPhone: string;
  private _bookingEmail: string;

  private _bookingsFullCorona = false;

  private _selectedX = -1;
  private _selectedY = -1;
  private _countAdults = 1;
  private _countChildren = 0;
  private _countTickets = 1;
  private _selectedSeats: Sitz[] = [];

  private _previousScreening: Vorstellung;
  private _nextScreening: Vorstellung;

  private _isSeating = false;
  private availableStandingTickets = 0;

  private blockScreenings: Vorstellung[] = [];

  processingDots = '';


  constructor(
    @Inject(DOCUMENT) private document: any,
    private globals: Globals,
    private userService: UserService,
    private screeningService: ScreeningService,
    private pricingService: PricingService,
    private movieService: MovieService,
    private bookingService: BookingService,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private translation: L10nTranslationService,
    startupService: StartupService,
    titleService: Title, meta: Meta) {
      super(titleService, startupService, meta);
    this.globals.userSource$.subscribe(() => this.handleUserChange());
  }

  ngOnInit() {
    this.maxSeatsInBooking = this.startupService.getConfig().maxSeatsInBooking;
    this.maxSeatsInBookingList = [];
    for (let i = 1; i <= this.maxSeatsInBooking; i++) {
      this.maxSeatsInBookingList.push(i);
    }

    this.maxSeatsChildrenList = [];
    this.maxSeatsChildrenList.push(0);
    if (!this.useBlockLogic) this.maxSeatsChildrenList.push(1);

    this.viewState = ComponentStatus.Loading;
    this.handleUserChange();

    // if we are still here, we can continue
    this.route.paramMap.subscribe(map => {
      const screeningId = map.get('id');
      if (screeningId == null) {
        this.errorText = this.translation.translate('ScreeningInfo_Error');
        return;
      }

      // get data
      forkJoin([
        this.screeningService.getByScreeningId(screeningId),
        this.pricingService.getByScreeningId(screeningId),
        this.movieService.getByScreeningId(screeningId),
        this.bookingService.getBookedSeatsForScreening(screeningId),
        this.screeningService.getNextScreening(screeningId),
        this.screeningService.getPreviousScreening(screeningId),
        this.bookingService.getOpenBooking(screeningId),
        this.screeningService.getAvailableStandingTickets(screeningId),
        this.screeningService.getScreeningsForBlock(screeningId)
      ]).subscribe(results => {
        this.screening = <Vorstellung>results[0];
        this.pricing = <PreisSchema>results[1];
        this.movie = <Film>results[2];
        this.booking = <Reservierung>results[3];
        this._nextScreening = <Vorstellung>results[4];
        this._previousScreening = <Vorstellung>results[5];
        const openBooking = <Reservierung>results[6];
        this.availableStandingTickets = results[7];
        this.blockScreenings = results[8];

        const isSeating = this.screening.saalArt === SaalArt.Sitzplaetze;
        this._isSeating = isSeating;

        if (openBooking != null) {
          // forward!
          this.router.navigate(['/booking/' + openBooking.id]);
        }

        // get additional data
        this.bookingService.getBookingsForScreening(screeningId).subscribe(res => {
          this.bookings = res;
        });
        this.bookingService.getDoubleBookedSeatsForScreening(screeningId).subscribe(res => {
          this.doubleBookedSeats = res;
        });

        if (this.screening == null || this.pricing == null || this.movie == null || this.booking == null) {
          this.errorText = this.translation.translate('ScreeningInfo_Error');
        } else if (!this.isActive(this.screening) && !this.isMitarbeiter) {
          this.booking = new Reservierung();
          this.bookings = [];

          this.errorText = 'Für diese Vorstellung können leider keine Sitzplätze mehr reserviert oder gekauft werden.';
        } else {
          this.titleService.setTitle(this.startupService.getConfig().client + ' - ' + this.movie.filmTitel + ' ' + this.movie.filmTitelZusatz);
          this.viewState = ComponentStatus.Displaying;

          // start booking reloading logic
          this.reloadBooking();
        }
      });
    });
  }

  private reloadBooking(): void {
    const milliseconds = 60 * 1000;
    const tim = timer(milliseconds, milliseconds);
    tim.subscribe(val => {
      if (this.isActive(this.screening) || this.isMitarbeiter) {
        this.bookingService.getBookedSeatsForScreening(this.screening.vorstellungsId)
        .subscribe(booking => {
          this.booking = booking;
          if (booking == null) {
            this.errorText = this.translation.translate('ScreeningInfo_Error');
          } else {
            this.updateCoronaFull();
          }
        });
        this.screeningService.getAvailableStandingTickets(this.screening.vorstellungsId)
        .subscribe(amountAvailable => {
          this.availableStandingTickets = amountAvailable;
        });

        // get additional data
        this.bookingService.getBookingsForScreening(this.screening.vorstellungsId).subscribe(res => {
          this.bookings = res;
        });
      } else {
        this.booking = new Reservierung();
        this.bookings = [];

        this.errorText = 'Für diese Vorstellung können leider keine Sitzplätze mehr reserviert oder gekauft werden.';
      }
    });
  }

  get selectedX(): number {
    return this._selectedX;
  }

  set selectedX(newX: number) {
    this._selectedX = newX;
  }

  get selectedY(): number {
    return this._selectedY;
  }

  set selectedY(newY: number) {
    this._selectedY = newY;
  }

  get nextScreening(): Vorstellung {
    return this._nextScreening;
  }

  get previousScreening(): Vorstellung {
    return this._previousScreening;
  }

  get nextScreeningId(): string {
    return this._nextScreening != null ? this._nextScreening.vorstellungsId : '';
  }

  get previousScreeningId(): string {
    return this._previousScreening != null ? this._previousScreening.vorstellungsId : '';
  }

  get nextScreeningTime(): Date {
    return this._nextScreening != null ? this._nextScreening.vorstellungszeit : null;
  }

  get previousScreeningTime(): Date {
    return this._previousScreening != null ? this._previousScreening.vorstellungszeit : null;
  }

  get bookingsFullCorona(): boolean { return this._bookingsFullCorona; }
  set bookingsFullCorona(value: boolean) { this._bookingsFullCorona = value; }

  get canPerformBookingsCorona(): boolean {
    if (this.bookingsFullCorona || (this.booking != null && this.screening != null && ((this.countTickets + this.booking.sitzIds.length > 100) || (this.countTickets + this.booking.sitzIds.length > this.screening.sitze.length/2)))) {
      if (this.startupService.isBeforeCoronaEndDate(this.screening.vorstellungszeit)) {
        return false;
      }
    }
    return true;
  }

  private updateCoronaFull(): void {
    this.bookingsFullCorona = false; // this.booking.sitzIds.length >= this.screening.sitze.length * 0.75;
  }

  set countAdults(newCount: number) {
    newCount = +newCount;
    if (newCount < 0) {
      newCount = 0;
    }
    this._countAdults = newCount;
  }

  get countAdults(): number {
    return this._countAdults;
  }

  get countChildren(): number {
    return this._countChildren;
  }

  set countChildren(newCount: number) {
    newCount = +newCount;
    if (newCount < 0) {
      newCount = 0;
    }
    this._countChildren = newCount;
    this.countAdults = this.countTickets - newCount;
  }

  get countTickets(): number {
    return this._countTickets;
  }

  set countTickets(newCount: number) {
    newCount = +newCount;
    if (newCount < 0) {
      newCount = 0;
    }
    this._countTickets = newCount;

    // geierwally requires 1 adult, so -1 on children here
    this.maxSeatsChildrenList = [];
    if (this.useBlockLogic) {
      for (let i = 0; i <= newCount-1; i++) {
        this.maxSeatsChildrenList.push(i);
      }

      if (this.countChildren > newCount-1) {
        this.countChildren = newCount-1;
      }
    } else {
      for (let i = 0; i <= newCount; i++) {
        this.maxSeatsChildrenList.push(i);
      }

      if (this.countChildren > newCount) {
        this.countChildren = newCount;
      }
    }


    this.countAdults = this.countTickets - this.countChildren;
    this.onSeatSelectedChanged([this.selectedX, this.selectedY]);
  }

  get selectedSeats(): Sitz[] {
    return this._selectedSeats;
  }

  set selectedSeats(newValue: Sitz[])  {
    this._selectedSeats = newValue;
  }

  isSeatSelected(): boolean {
    return this.selectedSeats.length > 0 || (this.countTickets > 0 && !this.isSeating);
  }

  handleUserChange(): void {
    if (this.globals.isAutoLoginInProgress()) {
      this.globals.autoLoginInProgressSource$.subscribe(autoLoginInProgress => {
        this.handleUserChangeCore();
      });
    } else {
      this.handleUserChangeCore();
    }
  }

  handleUserChangeCore(): void {
    if (this.globals.isLoggedIn()) {
      const user = this.globals.getUser();
      const canBook = this.userService.hasUserRight(user, UserRight.Booking);
      const isBanned = this.userService.hasUserRight(user, UserRight.Banned);

      if (!canBook || isBanned) {
        // TODO send away with error message
      }
    } else if (this.startupService.getConfig().requiresImmediateLogin) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.route.snapshot.url }
      });
    }
  }

  getPricingCategory(): Kategorie {
    if (this.isSeatSelected() && !this.isLoading) {
      let categoryId = -1;

      // with no seating, always first category
      if (!this.isSeating) {
        return this.pricing.kategorien[0];
      }

      for (const seat of this.screening.sitze) {
        if (seat.x === this.selectedX && seat.y === this.selectedY) {
          categoryId = seat.kategorieId;
          break;
        }
      }

      if (categoryId !== -1) {
        for (const category of this.pricing.kategorien) {
          if (category.id === categoryId) {
            return category;
          }
        }
      }
    }
    return null;
  }

  getPricingAdults(): number {
    return this.getPricing(0);
  }

  getPricingChildren(): number {
    return this.getPricing(1);
  }

  getPricingTotal(): number {
    const pricingAdults = this.getPricingAdults();
    const pricingChildren = this.getPricingChildren();
    return (
      pricingAdults * this.countAdults + pricingChildren * this.countChildren
    );
  }

  private getPricing(priceIndex: number): number {
    if (this.isSeatSelected() && !this.isLoading) {
      // assumption: all seats must have same category
      const category = this.getPricingCategory();
      if (category != null) {
        const price = category.preise[priceIndex];
        let sum = price.preis;

        if (this.screening.aufschlagAktiv) {
          sum += this.screening.aufschlagSumme;
        }
        if (this.screening.aufschlagAktiv3D) {
          sum += this.screening.aufschlagSumme3D;
        }
        return sum;
      }
    }
    return -1;
  }

  private getSeat(x: number, y: number): Sitz {
    if (this.screening != null) {
      for (const seat of this.screening.sitze) {
        if (seat.x === x && seat.y === y) {
          return seat;
        }
      }
    }

    return null;
  }

  onSeatSelectedChanged(selected: [number, number]) {
    let selectedX = selected[0];
    const selectedY = selected[1];
    this.viewState = ComponentStatus.Displaying;

    // check if hall is 8 wide or not by getting seat at x = 8 and x = 9 (if 8 was found and 9 not, then 8 wide)
    const seatThree = this.getSeat(3, selectedY);
    const seatEight = this.getSeat(8, selectedY);
    const seatNine = this.getSeat(9, selectedY);
    const isEightWide = seatEight != null && seatNine == null;
    const tryToMoveForEightWide = isEightWide && this.countTickets === 2 && selectedX === 4 && seatThree != null;

    let moveForEightWide = false;

    if (tryToMoveForEightWide && !this.isSeatBooked(seatThree)) {
      selectedX = 3;
      moveForEightWide = true;
    }

    // get seat which was clicked
    const seat = this.getSeat(selectedX, selectedY);
    if (seat != null && seat.typ !== SitzTyp.Platzhalter && !this.isSeatBooked(seat)) {
      // clear selected seats and assign new position
      this.selectedSeats = [];
      this.selectedX = selectedX;
      this.selectedY = selectedY;

      // create local list
      let selectedSeats: Sitz[] = [];

      // select initial seat
      if (this.countTickets > 0) {
        selectedSeats.push(seat);
      }

      // helpers to store most right and left seats
      let mostRightSeat = this.selectedX;
      let mostLeftSeat = this.selectedX;

      // select seats towards right until occupied or non existant seat is found
      let currentX = this.selectedX;
      let openTickets =  this.countTickets - 1;
      while (openTickets > 0) {
        currentX++;
        const nextSeat = this.getSeat(currentX, this.selectedY);

        if (nextSeat != null && nextSeat.typ !== SitzTyp.Platzhalter && !this.isSeatBooked(nextSeat)) {
          selectedSeats.push(nextSeat);
          openTickets--;

          // inc most right seat position (X)
          mostRightSeat++;
        } else {
          break;
        }
      }

      // select seats towards left until occupied or non existant seat is found
      currentX = this.selectedX;
      while (openTickets > 0) {
        currentX--;
        const nextSeat = this.getSeat(currentX, this.selectedY);
        if (nextSeat != null && nextSeat.typ !== SitzTyp.Platzhalter && !this.isSeatBooked(nextSeat)) {
          selectedSeats.push(nextSeat);
          openTickets--;

          // dec most left seat position (X)
          mostLeftSeat--;
        } else {
          break;
        }
      }

      // if we still have open ticket, there was not enough room, show error
      if (openTickets > 0) {
        this.warningText = this.translation.translate('ScreeningInfo_ErrorSeats');
      } else {
        // if selected seat is a partner seat right side and number of tickets > 1 then shift one to the left
        // do not apply rest of logistics if this is the case
        const currentSeat = this.getSeat(mostLeftSeat, this.selectedY);
        const partnerLeftSeat = this.getSeat(mostLeftSeat - 1, this.selectedY);
        if (currentSeat.typ === SitzTyp.PartnerRechts && this.countTickets > 1 && partnerLeftSeat != null && !this.isSeatBooked(partnerLeftSeat)) {
          const rightSeat = this.getSeat(mostRightSeat, this.selectedY);
          const index = selectedSeats.indexOf(rightSeat);
          selectedSeats.splice(index, 1);
          selectedSeats.push(partnerLeftSeat);

          this.warningText = this.translation.translate('ScreeningInfo_LogisticsWarning');
        } else {
        // check if we should move seats to the left to avoid single-empty seats
        const notAllowedDiff = Math.max(1, Math.min(Math.floor(this.countTickets / 2), 2));
        let countFreeLeft = this.getLeftFreeSeatsCount(mostLeftSeat, notAllowedDiff + 1);
        let countFreeRight = this.getRightFreeSeatsCount(mostRightSeat, notAllowedDiff + 1);

        // moving to left wins
        if (countFreeLeft <= notAllowedDiff && countFreeLeft > 0) {
          while (countFreeLeft > 0) {
            const rightSeat = this.getSeat(mostRightSeat, this.selectedY);
            const newSeat = this.getSeat(mostLeftSeat - 1, this.selectedY);
            const index = selectedSeats.indexOf(rightSeat);
            selectedSeats.splice(index, 1);
            selectedSeats.push(newSeat);

            mostRightSeat--;
            mostLeftSeat--;
            countFreeLeft--;
          }
          this.warningText = this.translation.translate('ScreeningInfo_LogisticsWarning');
        } else if (countFreeRight <= notAllowedDiff && countFreeRight > 0) {
          while (countFreeRight > 0) {
            const leftSeat = this.getSeat(mostLeftSeat, this.selectedY);
            const newSeat = this.getSeat(mostRightSeat + 1, this.selectedY);
            const index = selectedSeats.indexOf(leftSeat);
            selectedSeats.splice(index, 1);
            selectedSeats.push(newSeat);

            mostRightSeat++;
            mostLeftSeat++;
            countFreeRight--;
          }
          this.warningText = this.translation.translate('ScreeningInfo_LogisticsWarning');
        } else if (moveForEightWide) {
          this.warningText = this.translation.translate('ScreeningInfo_LogisticsWarning');
        } else {
          this.viewState = ComponentStatus.Displaying;
        }
      }
    }

    selectedSeats = this.sortSeatsByX(selectedSeats);

    // special case: if most right seat is a double seat, move everything one to the right again ...
    if (this.countTickets > 1) {
      const lastSeat = selectedSeats[selectedSeats.length - 1];
      if (lastSeat.typ === SitzTyp.PartnerLinks) {
        // get next seat, check if free ...
        const nextSeat = this.getSeat(lastSeat.x + 1, lastSeat.y);
        if (!this.isSeatBooked(nextSeat)) { // move
          selectedSeats.shift();
          selectedSeats.push(nextSeat);
          this.warningText = this.translation.translate('ScreeningInfo_LogisticsWarning');
        }
      }
    }

    this.selectedSeats = selectedSeats;
    }
  }

  getLeftFreeSeatsCount(lastUsedX: number, maxDist: number): number {
    return this.getFreeSeatsCount(lastUsedX, maxDist, -1);
  }

  getRightFreeSeatsCount(lastUsedX: number, maxDist: number): number {
    return this.getFreeSeatsCount(lastUsedX, maxDist, +1);
  }

  private getFreeSeatsCount(lastUsedX: number, maxDist: number, increment: number): number {
    lastUsedX += increment;

    let count = 0;

    while (true) {
      // if end is reached, cancel counting
      if (lastUsedX < 0) {
        break;
      }

      // if we do n ot find a seat or of the seat is a place holder or if the seat is booked, cancel counting
      const seat = this.getSeat(lastUsedX, this.selectedY);
      if (seat == null || seat.typ === SitzTyp.Platzhalter || this.isSeatBooked(seat)) {
        break;
      }

      // count and go on
      count++;
      lastUsedX += increment;

      if (count >= maxDist) {
        break;
      }
    }

    return count;
  }

  canPerformBooking(): boolean {
    const countGreaterZero = this.countTickets > 0;
    const selectedSeatsMatch = this.selectedSeats.length === this.countTickets || !this.isSeating;
    const result = countGreaterZero && selectedSeatsMatch && !this.isError;

    return result;
  }

  performBooking(): void {
    if (this.globals.isLoggedIn()) {
      this.performBookingCore();
    } else {
      this.dialogService.showLoginDialog().then(result => {
          if (result == 'done') this.performBooking(); // done means successful login, if so continue with booking
      });
    }
  }

  private performBookingCore(): void {
    this.viewState = ComponentStatus.Processing;

    let countDots = 1;
    this.processingDots = '';
    const interval = setInterval(() => {
      if (countDots === 0) {
        this.processingDots = '';
        countDots ++;
      } else if (countDots === 1) {
        this.processingDots = ' .';
        countDots ++;
      } else if (countDots === 2) {
        this.processingDots = ' ..';
        countDots ++;
      } else if (countDots === 3) {
        this.processingDots = ' ...';
        countDots = 0;
      }
    }, 500);

    const booking = new Reservierung();
    booking.vorstellungsId = this.screening.vorstellungsId;
    booking.userId = this.globals.getUser().id;
    for (const sitz of this.selectedSeats) {
      booking.sitzIds.push(sitz.id);
    }

    booking.anzahlErwachsene = this.countAdults;
    booking.anzahlKinder = this.countChildren;

    if (this.countTickets > this.availableStandingTickets && !this.isSeating) {
      this.errorText = `Sie haben mehr Tickets gewählt als noch verfügbar sind. Es gibt aktuell noch ${this.availableStandingTickets} Tickets.`;
      return;
    }

    // set name if it is configured, usually this means a Mitarbeiter is booking for a customer
    if (this.bookingName != null && this.bookingName.length > 0) {
      booking.reservierungsName = this.bookingName;
    }
    if (this.bookingEmail != null && this.bookingEmail.length > 0) {
      booking.reservierungsEmail = this.bookingEmail;
    }
    if (this.bookingPhone != null && this.bookingPhone.length > 0) {
      booking.reservierungsTelefon = this.bookingPhone;
    }

    this.bookingService.create(booking)
    .subscribe(book => {
      clearInterval(interval);
      if (book == null) {
        this.errorText = this.translation.translate('ScreeningInfo_ErrorProcessing', { homepage: this.startupService.getConfig().clientHomepage});
      } else {
        this.router.navigate(['/booking/' + book.id]);
      }
    });
  }

  private isSeatBooked(seat: Sitz): boolean {
    const index = this.booking.sitzIds.indexOf(seat.id);
    return index > -1;
  }

  getSelectedRowName(): string {
    if (this.selectedSeats.length === 0) {
      return '';
    }

    return '' + this.selectedSeats[0].reihe;
  }

  getSelectedSeatsLabelKey(): string {
    if (this.selectedSeats.length > 1) {
      return 'ScreeningInfo_SelectedSeats';
    }
    return 'ScreeningInfo_SelectedSeat';
  }

  getSelectedSeatsNames(): string {
    if (this.selectedSeats.length === 0) {
      return '';
    }

    let label = '' + this.selectedSeats[0].sitzplatzNummer;
    if (this.selectedSeats.length > 1) {
      label += ' - ' + this.selectedSeats[this.selectedSeats.length - 1].sitzplatzNummer;
    }

    return label;
  }

  showChildrenSelection(): boolean {
    // guard
    if (this.screening == null) {
      return false;
    }

    return this.screening.kinderTicketsErlaubt;
  }

  getDayInWeek(): string {
    let dayInWeek = 0;
    if (this.screening != null) {
      const date = new Date(this.screening.vorstellungszeit);
      dayInWeek = date.getDay();
    }

    let dayKey = '-';
    switch (dayInWeek) {
      case 0: {
        dayKey = 'Day_Sunday';
        break;
      }
      case 1: {
        dayKey = 'Day_Monday';
        break;
      }
      case 2: {
        dayKey = 'Day_Tuesday';
        break;
      }
      case 3: {
        dayKey = 'Day_Wednesday';
        break;
      }
      case 4: {
        dayKey = 'Day_Thursday';
        break;
      }
      case 5: {
        dayKey = 'Day_Friday';
        break;
      }
      case 6: {
        dayKey = 'Day_Saturday';
        break;
      }
    }
    return this.translation.translate(dayKey);
  }

  sortSeatsByX(selectedSeats: Sitz[]): Sitz[] {
    return selectedSeats.sort((a, b) => {
      const x1 = a.x;
      const x2 = b.x;

      if (x1 < x2) {
        return -1;
      } else if (x1 > x2) {
        return 1;
      }
      return 0;
    });
  }

  get isMitarbeiter(): boolean {
      const user = this.globals.getUser();
      if (user == null) {
        return false;
      }

      const isMitarbeiter = this.userService.hasUserRight(user, UserRight.Mitarbeiter);
      const isAdmin = this.userService.hasUserRight(user, UserRight.Admin);
      return isMitarbeiter || isAdmin;
  }

  set bookingName(value: string) {
    this._bookingName = value;
  }

  get bookingName(): string {
    return this._bookingName;
  }

  set bookingPhone(value: string) {
    this._bookingPhone = value;
  }

  get bookingPhone(): string {
    return this._bookingPhone;
  }

  set bookingEmail(value: string) {
    this._bookingEmail = value;
  }

  get bookingEmail(): string {
    return this._bookingEmail;
  }

  get childAgeLimit(): string {
    return this.startupService.getConfig().childAgeLimit.toFixed(0);
  }

  cancelBooking(): void {
    const url = this.startupService.getConfig().bookingCancelUrl;
    this.document.location.href = url;
  }

  isActive(screening: Vorstellung): boolean {
    const screeningDate = new Date(screening.vorstellungszeit);
    const currentDate = Date.now();

    const diffMilliseconds = screeningDate.getTime() - currentDate;
    const targetMillis = this.startupService.getConfig().bookingTimeLimit * 60 * 1000;

    const isActiveTime = targetMillis < diffMilliseconds;
    const isActiveSetting = screening.reservierungsEinstellung !== 'Gesperrt';
    return isActiveTime && isActiveSetting;
  }

  get useBlockLogic(): boolean {
    return this.startupService.getConfig().useBlockLogic;
  }

  public isSeatCountSelected(countTickets: number, seatCountNumber: number): boolean {
    return this.countTickets >= seatCountNumber;
  }

  public isSeatCountChildrenSelected(countTickets: number, seatCountNumber: number): boolean {
    return this.countChildren >= seatCountNumber;
  }

  public selectSeatCount(seatCount: number): void {
    this.countTickets = seatCount;
  }

  public selectSeatCountChildren(seatCount: number): void {
    this.countChildren = seatCount;
  }

  public get showIcons(): boolean {
      return true;
    // return this.startupService.getConfig().maxSeatsInBooking <= 8;
  }

  get countTicketsSold(): number {
    let countSold = 0;

    for (const book of this.bookings) {
      if (book.status === Reservierungsstatus.VerkauftInternet || book.status === Reservierungsstatus.VerkauftKasse || book.status === Reservierungsstatus.AusgedrucktInternet) {
        countSold += book.anzahlErwachsene;
        countSold += book.anzahlKinder;
      }
    }

    return countSold;
  }

  get countTicketsBooked(): number {
    let countBooked = 0;

    for (const book of this.bookings) {
      if (book.status === Reservierungsstatus.InternetReserviert || book.status === Reservierungsstatus.Reserviert) {
        countBooked += book.anzahlErwachsene;
        countBooked += book.anzahlKinder;
      }
    }

    return countBooked;
  }

  get doubleBookedSeatsString(): string {
    let result = '';
    for (const seat of this.doubleBookedSeats) {
      result = result + seat.id + ' ';
    }
    return result;
  }

  get hasCustomHtml(): boolean {
    if (this.screening != null)
    {
      return this.screening.customHtml != null && this.screening.customHtml.length > 0;
    }
    return false;
  }

  get isSeating(): boolean { return this._isSeating; }
}
