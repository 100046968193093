import { AdminService } from './../../services/admin.service';
import { Config } from './../../models/config';
import { Globals } from './../../models/globals';
import { UserRight } from './../../models/enums/userRight';
import { User } from './../../models/user';
import { AuthenticationService } from '../../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { DialogService } from '../../services/dialog-service.service';
import { UserService } from '../../services/user.service';
import { StartupService } from '../../services/startup.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html'
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  isLoggedIn = false;
  isAdmin = false;
  isMitarbeiter = false;
  isCorrectTimeZone = true;
  config: Config;
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor(
    private authService: AuthenticationService,
    private userService: UserService,
    private dialogService: DialogService,
    private globals: Globals,
    private startupService: StartupService,
    private adminService: AdminService
  ) {
      this.globals.userSource$.subscribe(user => { this.handleUserChange(user); });
      this.config = this.startupService.getConfig();
   }

  ngOnInit(): void {
    this.userService.verifyTimeZone().subscribe(isCorrectTimeZone => {
    this.isCorrectTimeZone = isCorrectTimeZone;
  });
  }

  collapse(): void {
    this.isExpanded = false;
  }

  toggle(): void {
    this.isExpanded = !this.isExpanded;
  }

  login(): void {
    this.dialogService.showLoginDialog()
    .then();
  }

  logout(): void {
    this.globals.removeUser();
    this.authService.logout(true);
  }

  handleUserChange(user: User): void {
    this.isLoggedIn = this.globals.isLoggedIn();
    if (this.isLoggedIn) {
      this.isAdmin = this.userService.hasUserRight(user, UserRight.Admin);
      this.isMitarbeiter = this.userService.hasUserRight(user, UserRight.Mitarbeiter);

      this.userService.verifyTimeZone().subscribe(isCorrectTimeZone => {
        this.isCorrectTimeZone = isCorrectTimeZone;
      });
    } else {
      this.isAdmin = false;
      this.isMitarbeiter = false;
    }
  }

  getUserName(): string {
    if (this.isLoggedIn) {
      const user = this.globals.getUser();
      return user.firstName.charAt(0) + '. ' + user.lastName;
    }
    return '';
  }

  get isStaticPagesActive(): boolean {
    return this.startupService.getConfig().staticPagesActive;
  }
}
