import { Injectable, Optional } from '@angular/core';
import { L10nTranslationLoader, L10nProvider, L10nConfig } from 'angular-l10n';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable() export class HttpTranslationLoader implements L10nTranslationLoader {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(@Optional() private http: HttpClient) { }

  public get(language: string, provider: L10nProvider): Observable<{ [key: string]: any }> {
      const url = `${provider.asset}-${language}.json`;
      const options = {
          headers: this.headers,
          params: new HttpParams().set('v', provider.options.version)
      };
      return this.http.get(url, options);
  }
}

// config for localisation
export const l10nConfig: L10nConfig = {
  format: 'language-region',
  providers: [
    { name: 'locale', asset: './assets/localisation/locale', options: { version: '1.0.0' }},
    { name: 'user', asset: './assets/localisation/user', options: { version: '1.0.0' }},
    { name: 'configviewer', asset: './assets/localisation/configviewer', options: { version: '1.0.0' }},
    { name: 'screeninginfo', asset: './assets/localisation/screeninginfo', options: { version: '1.0.0' }},
    { name: 'program', asset: './assets/localisation/program', options: { version: '1.0.0' }},
    { name: 'nav', asset: './assets/localisation/nav', options: { version: '1.0.0' }},
    { name: 'payment', asset: './assets/localisation/payment', options: { version: '1.0.0' }},
    { name: 'admin', asset: './assets/localisation/admin', options: { version: '1.0.0' }}
  ],
  cache: true,
  fallback: false,
  keySeparator: '.',
  defaultLocale: { language: 'de-DE', timeZone: 'Europe/Berlin', currency: 'EUR' },
  schema: [
    { locale: { language: 'en-US', currency: 'USD', timeZone: 'Europe/Berlin' }, dir: 'ltr', text: 'United States' },
    { locale: { language: 'de-DE', currency: 'EUR', timeZone: 'Europe/Berlin' }, dir: 'ltr', text: 'Deutschand/Österreich/Schweiz' }
  ]
};
