import { Component } from '@angular/core';
import { ComponentStatus } from '../../../models/enums/componentStatus';
import { Meta, Title } from '@angular/platform-browser';
import { StartupService } from '../../../services/startup.service';

@Component({
  selector: 'app-view-base',
  templateUrl: './view-base.component.html'
})
export class ViewBaseComponent {
  private _viewState: ComponentStatus = ComponentStatus.Unknown;
  private _messageText: string;

  constructor(protected titleService: Title, protected startupService: StartupService, protected meta: Meta) {
    this.titleService.setTitle(this.startupService.getConfig().client + ' - Online-Reservierung');

    // set default tags
    meta.updateTag({ name: 'description', content: this.startupService.getConfig().client + ' - Online-Reservierung' });
    meta.updateTag({ name: 'og:title', content: this.startupService.getConfig().client + ' - Online-Reservierung' });
    meta.removeTag("name='og:image'");
  }

  protected updateTags(title: string, description: string, image: string) {
    this.meta.updateTag({name: 'og:title', content: title});
    this.meta.updateTag({name: 'description', content: description});
    this.meta.updateTag({name: 'og:image', content: image});
  }

  protected isStatus(state: ComponentStatus): boolean {
    return this._viewState === state;
  }

  get isWarning(): boolean {
    return this.isStatus(ComponentStatus.Warning);
  }

  get isError(): boolean {
    return this.isStatus(ComponentStatus.Error);
  }

  get isDisplaying(): boolean {
    return this.isStatus(ComponentStatus.Displaying);
  }

  get isLoading(): boolean {
    return this.isStatus(ComponentStatus.Loading);
  }

  get isProcessing(): boolean {
    return this.isStatus(ComponentStatus.Processing);
  }

  get errorText(): string {
    return this._messageText;
  }

  set errorText(errorText: string) {
    this.viewState = ComponentStatus.Error;
    this._messageText = errorText;
  }

  get warningText(): string {
    return this._messageText;
  }

  set warningText(warningText: string) {
    this.viewState = ComponentStatus.Warning;
    this._messageText = warningText;
  }

  get viewState(): ComponentStatus {
    return this._viewState;
  }

  set viewState(value: ComponentStatus) {
    this._viewState = value;
    this._messageText = '';
  }

}
