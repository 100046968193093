import { Component, HostListener, Input, OnChanges, SimpleChanges } from "@angular/core";
import Konva from "konva";
import { Vorstellung } from "../../../models/vorstellung";

@Component({
    selector: 'app-standing-info',
    templateUrl: './standing-info.component.html'
  })
  export class StandingInfoComponent implements OnChanges {
    @Input()
    screening: Vorstellung;

    @Input()
    availableStandingTickets: number;
    
  // konva stage object
  _stage: Konva.Stage = null;

  canvasWidthMax = 300;
  canvasHeightMax = 300;
  _canvasWidth = 10; // never go below, iOS ...
  _canvasHeight = 10; // never go below, iOS ...

  @HostListener('window:resize', ['$event'])
  onSizeChanged(event) {
    if (this.screening != null) {
      this.drawStandingArea();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.screening != null) {
      this.drawStandingArea();
    }
  }

  private drawStandingArea(): void {
    if(this.screening) {
      // resize canvas to size of the hall
      this.updateCanvasSize();
      
      // create layers
      const staticLayer = new Konva.Layer();

      // draw placeholder
      // const backgroundBox = this.createRect(10, 10, 280, 280, '#00a9ff', 1.0);
      // staticLayer.add(backgroundBox);
      const border = new Konva.Rect({
        x: 10,
        y: 10,
        width: this._canvasWidth - 20,
        height: this._canvasHeight - 20,
        fill: '#a4ffa2',
        stroke: 'black',
        strokeWidth: 2
      });
      staticLayer.add(border);

      // draw placeholder text
      const header = this.createText("Stehplätze", 15, 15, true);
      staticLayer.add(header);
      const info1 = this.createText(`${this.availableStandingTickets} von ${this.screening.anzahlStehplaetze} Stehplätzen`, 15, 40, false);
      const info2 = this.createText(`verfügbar.`, 15, 65, false);
      staticLayer.add(info1);
      staticLayer.add(info2);
      
      // render hall
      this._stage.add(staticLayer);
      this._stage.draw();
    }
  }

  private updateCanvasSize(): void {
    const screenWidth = screen.width;

    this._canvasWidth = window.innerWidth - 125;
    if (this._canvasWidth > 300) {
      this._canvasWidth = 300;
    } else if (this._canvasWidth < 150) {
      this._canvasWidth = 150;
    }
    this._canvasHeight = this._canvasWidth;
    
    // now create stage
    this._stage = new Konva.Stage({
        container: 'standingPlaceholderContainer',
        width: this._canvasWidth,
        height: this._canvasHeight
      });
      this._stage.setAttr('component', this);
  }

  private createRect(
    left: number,
    top: number,
    width: number,
    height: number,
    color: string,
    opacity: number
  ): Konva.Rect {
    const rect = new Konva.Rect({
      x: left,
      y: top,
      fill: color,
      width: width,
      height: height,
      strokeWidth: 1,
      opacity: opacity
    });

    return rect;
  }

  private createText(content: string, left: number, top: number, isHeader: boolean): Konva.Text {
    const fontSize = isHeader ? 20 : 16;
    const text = new Konva.Text({
      x: left,
      y: top,
      text: content,
      fontSize: fontSize,
      fontFamily: 'Arial',
      fill: 'black'
    });
    return text;
  }

    
  }