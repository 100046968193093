import { AuthenticationService } from './authentication.service';
import { LoginDialogComponent } from './../components/dialogs/login-dialog/login-dialog.component';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserEditDialogComponent } from '../components/dialogs/userEdit-dialog/userEdit-dialog.component';
import { User } from '../models/user';
import { UserService } from './user.service';
import { Reservierung } from '../models/reservierung';
import { BarcodeDialogComponent } from '../components/dialogs/barcode-dialog/barcode-dialog.component';
import { QRCodeDialogComponent } from '../components/dialogs/qrcode-dialog/qrcode-dialog.component';
@Injectable()
export class DialogService {

  constructor(private modalService: NgbModal, private authService: AuthenticationService, private userService: UserService) { }

  public showLoginDialog(): Promise<any> {
    const modalRef = this.modalService.open(LoginDialogComponent);
    modalRef.componentInstance.authService = this.authService;
    return modalRef.result;
  }

  public showUserEditDialog(user: User): Promise<any> {
    const modalRef = this.modalService.open(UserEditDialogComponent);
    modalRef.componentInstance.userService = this.userService;
    modalRef.componentInstance.user = user;
    return modalRef.result;
  }

  public showBarcodeDialog(booking: Reservierung): Promise<any> {
    const modalRef = this.modalService.open(BarcodeDialogComponent);
    modalRef.componentInstance.booking = booking;
    return modalRef.result;
  }

  public showQRCodeDialog(booking: Reservierung): Promise<any> {
    const modalRef = this.modalService.open(QRCodeDialogComponent);
    modalRef.componentInstance.booking = booking;
    return modalRef.result;
  }
}
