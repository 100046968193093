import { Component, OnInit, Inject } from '@angular/core';
import { BookingService } from '../../../services/booking.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentStatus } from '../../../models/enums/componentStatus';
import { Vorstellung } from '../../../models/vorstellung';
import { Reservierung } from '../../../models/reservierung';
import { PreisSchema } from '../../../models/preisSchema';
import { ScreeningService } from '../../../services/screening.service';
import { PricingService } from '../../../services/pricing.service';
import { Film } from '../../../models/film';
import { Sitz } from '../../../models/sitz';
import { MovieService } from '../../../services/movie.service';
import { timer, forkJoin } from 'rxjs';
import { Reservierungsstatus } from '../../../models/enums/reservierungsstatus';
import { ViewBaseComponent } from '../../misc/view-base/view-base.component';
import { L10nTranslationService } from 'angular-l10n';
import { StartupService } from '../../../services/startup.service';
import { OwnMath } from '../../../helpers/OwnMath';
import { DateFormatPipe } from '../../../helpers/DateFormatPipe';
import { TimeFormatPipe } from '../../../helpers/TimeFormatPipe';
import {saveAs as importedSaveAs} from 'file-saver';
import { DOCUMENT } from '@angular/common';
import { Globals } from '../../../models/globals';
import { UserService } from '../../../services/user.service';
import { EPSService } from '../../../services/eps.service';
import { TaxSetService } from '../../../services/taxSetService';
import { TaxSet } from '../../../models/taxSet';
import { Meta, Title } from '@angular/platform-browser';
import { StripeService } from '../../../services/stripe.service';
import {loadStripe} from '@stripe/stripe-js/pure';
import { SaalArt } from '../../../models/enums/saalArt';
import { UserRight } from '../../../models/enums/userRight';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html'
})
export class BookingDetailsComponent extends ViewBaseComponent implements OnInit {
  private baseUrl: string;

  movie: Film;
  screening: Vorstellung;
  booking: Reservierung;
  pricing: PreisSchema;
  taxSet: TaxSet;

  priceChildren: number; // incl. tax
  taxChildren: number;
  priceAdults: number; // incl. tax
  taxAdults: number;
  taxTotal: number; // total tax
  subTotal: number; // sum without tax
  total: number; // subTotal + taxTotal
  serviceFeeEps: number;
  serviceFeeStripe: number;
  totalServiceFeeEps: number;
  totalServiceFeeStripe: number;

  timeLeft = '-:--';
  isStripeEnabled = false;
  isEpsEnabled = false;
  isBooked = false;
  isPaid = false;
  isTermsAccepted = false;
  isNewsletterAccepted = false;

  constructor(@Inject('BASE_URL') baseUrl: string, @Inject(DOCUMENT) private document: any, private bookingService: BookingService, private screeningService: ScreeningService
  , private pricingService: PricingService, private movieService: MovieService
    , private route: ActivatedRoute, private router: Router, private translation: L10nTranslationService, private globals: Globals
    , private userService: UserService, private epsService: EPSService, private taxSetService: TaxSetService
    , private stripeService: StripeService
    , startupService: StartupService, titleService: Title, meta: Meta) {
      super(titleService, startupService, meta);
    this.baseUrl = baseUrl;
   }

  ngOnInit() {
    this.viewState = ComponentStatus.Loading;

    this.route.paramMap.subscribe(
      map => {
        if (map.has('id')) {
          this.bookingService.get(map.get('id'))
          .subscribe(booking => {
            if (booking == null) {
              this.errorText = this.translation.translate('ScreeningInfo_Error');
            } else {
              this.booking = booking;

              // if booking is deleted or not in sync, go to bookings
              if (booking.isDeleted || !booking.reservierungBestaetigt) {
                this.router.navigate(['/bookings']);
              }

              this.isPaid = booking.status === Reservierungsstatus.VerkauftInternet
              || booking.status === Reservierungsstatus.VerkauftKasse
              || booking.status === Reservierungsstatus.AusgedrucktInternet;

              this.isBooked = booking.status === Reservierungsstatus.Reserviert || booking.status === Reservierungsstatus.InternetReserviert;

              forkJoin([
                this.screeningService.getByScreeningId(booking.vorstellungsId),
                this.movieService.getByScreeningId(booking.vorstellungsId),
                this.pricingService.getByScreeningId(booking.vorstellungsId),
                this.taxSetService.getByScreeningId(booking.vorstellungsId)
              ]).subscribe(results => {
                this.screening = results[0];
                this.movie = results[1];
                this.pricing = results[2];
                this.taxSet = results[3];

                if (this.screening != null && this.movie != null && this.pricing != null && this.taxSet != null) {
                  const ticketTax = (+1) + this.taxSet.taxTicket;
                  this.titleService.setTitle(this.startupService.getConfig().client + ' - ' + this.movie.filmTitel + ' ' + this.movie.filmTitelZusatz);

                  this.serviceFeeEps = this.startupService.getConfig().eps.serviceFee; // single service fee
                  this.serviceFeeStripe = this.startupService.getConfig().stripe.serviceFee; // single service fee
                  this.totalServiceFeeEps = (this.booking.anzahlErwachsene + this.booking.anzahlKinder) * this.serviceFeeEps;
                  this.totalServiceFeeStripe = (this.booking.anzahlErwachsene + this.booking.anzahlKinder) * this.serviceFeeStripe;

                  this.priceAdults = this.booking.preisErwachsene * this.booking.anzahlErwachsene;
                  this.priceChildren = this.booking.preisKinder * this.booking.anzahlKinder;
                  this.total = (this.priceAdults + this.priceChildren);
                  this.taxAdults = OwnMath.round(this.priceAdults - OwnMath.round(this.priceAdults / ticketTax, 2), 2);
                  this.taxChildren = OwnMath.round(this.priceChildren - OwnMath.round(this.priceChildren / ticketTax, 2), 2);
                  this.taxTotal = OwnMath.round(this.taxAdults + this.taxChildren, 2);
                  this.subTotal = this.total - this.taxTotal;

                  this.runTimer();
                  this.isEpsEnabled = this.startupService.getConfig().eps.enabled;
                  this.isStripeEnabled = this.startupService.getConfig().stripe.enabled;

                  this.isNewsletterAccepted = this.globals.getUser().newsletter;

                  this.viewState = ComponentStatus.Displaying;
                } else {
                  this.errorText = this.translation.translate('ScreeningInfo_Error');
                }
              });
            }
          });
        }
      });
  }


  confirmBooking(): void {
    this.viewState = ComponentStatus.Processing;

    forkJoin([
      this.bookingService.confirm(this.booking.id),
      this.userService.setNewsletter(this.globals.getUser().id, this.isNewsletterAccepted)
      ])
    .subscribe(res => {
      if (res[0]) {
        this.isBooked = true;
        this.viewState = ComponentStatus.Displaying;
      } else {
        this.errorText = this.translation.translate('ScreeningInfo_ErrorBookingConfirm');
      }
    });
  }

  confirmCashBooking(): void {
    this.viewState = ComponentStatus.Processing;

    this.bookingService.confirmCash(this.booking.id)
    .subscribe(res => {
      console.log('Res: ' + res);
      if (res) {
        this.isBooked = true;
        this.isPaid = true;
        this.viewState = ComponentStatus.Displaying;
      } else {
        this.errorText = this.translation.translate('ScreeningInfo_ErrorBookingConfirm');
      }
    });
  }

  initiateEpsTransfer(): void {
    this.viewState = ComponentStatus.Processing;

    this.epsService.initiateTransfer(this.booking.id)
    .subscribe(res => {
      if (res.success) {
        this.document.location.href = res.forwardUrl;
      } else {
        this.errorText = this.translation.translate('ScreeningInfo_Error');
      }
    });
  }

  async initiateStripeTransfer(): Promise<void> {
    this.viewState = ComponentStatus.Processing;
    const stripe = await loadStripe(this.startupService.getConfig().stripe.publicKey);

    this.stripeService.initiateTransfer(this.booking.id)
    .subscribe(res => {
      const sessId = (<any>res).id;
      stripe.redirectToCheckout({sessionId: sessId})
      .then(result => {
        if (result.error) {
          this.errorText = result.error.message;
        } else {
          this.viewState = ComponentStatus.Displaying;
        }
      })
      .catch(err => {
        console.error(err);
        this.errorText = "Fehler beim Aufruf der Stripe Zahlung.";
      });
    });
  }

  cancelBooking(): void {
    this.viewState = ComponentStatus.Processing;
    this.bookingService.delete(this.booking.id)
    .subscribe(res => {
      if (res) {
        const url = this.startupService.getConfig().bookingCancelUrl;
        this.document.location.href = url;
      } else {
        this.errorText = this.translation.translate('ScreeningInfo_Error');
      }
    });
  }


  getSelectedSeatsLabelKey(): string {
    if (this.booking.sitzIds.length > 1) {
      return 'ScreeningInfo_SelectedSeats';
    }
    return 'ScreeningInfo_SelectedSeat';
  }

  getSelectedSeatsNames(): string {
    if (this.booking.sitzIds.length === 0) {
      return '';
    }

    const seatId = this.booking.sitzIds[0];
    let seat = this.getSeat(seatId);
    let label = '' + seat.sitzplatzNummer;
    if (this.booking.sitzIds.length > 1) {
      seat = this.getSeat(this.booking.sitzIds[this.booking.sitzIds.length - 1]);
      label += ' - ' + seat.sitzplatzNummer;
    }

    return label;
  }

  private getSeat(seatId: string): Sitz {
    for (const seat of this.screening.sitze) {
      if (seat.id === seatId) {
        return seat;
      }
    }

    return null;
  }

  getSelectedRowName(): string {
    const seat = this.getSeat(this.booking.sitzIds[0]);
    if (seat === null) return '';
    return '' + seat.reihe;
  }

  getBarcodeUrl(): string {
    if (this.booking != null && this.isBooked) {
      return this.baseUrl + 'api/Booking/Barcode/' + this.booking.id;
    }
    return '//:0';
  }

  getQRCodeUrl(): string {
    if (this.booking != null) {
      return this.baseUrl + 'api/Booking/QRCode/' + this.booking.id;
    }
    return '//:0';
  }

  private runTimer(): void {
    const timeStart = new Date(this.booking.reservierungszeitpunkt).getTime();
    const tim = timer(1000, 1000);
    tim.subscribe(val => {
      const timeLimit = 300; //seconds = 5 minutes
      const elapsed = Date.now() - timeStart;
      const left = (timeLimit * 1000) - elapsed;
      const time = new Date(0, 0, 0, 0, 0, 0, left);

      if (time.getSeconds() < 10) {
        this.timeLeft = time.getMinutes() + ':0' + time.getSeconds();
      } else {
        this.timeLeft = time.getMinutes() + ':' + time.getSeconds();
      }

      if (elapsed > timeLimit * 1000 && !this.isError && !this.isProcessing && !this.isBooked && !this.isPaid) {
        this.warningText = this.translation.translate('BookingDetails_TimeoutError');
      }
    });
  }

  get isBookingAllowed(): boolean {
    return this.screening != null && (this.screening.reservierungsEinstellung === 'KaufUndReservierung' || this.screening.reservierungsEinstellung === 'Reservierung');
  }

  get isBuyingAllowed(): boolean {
    return this.screening != null && (this.screening.reservierungsEinstellung === 'KaufUndReservierung' || this.screening.reservierungsEinstellung === 'Kauf') && (this.startupService.getConfig().stripe.enabled || this.startupService.getConfig().eps.enabled);
  }

  get termsUrl(): string {
    return this.startupService.getConfig().clientTerms;
  }

  get isStaticPagesActive(): boolean {
    return this.startupService.getConfig().staticPagesActive;
  }

  get newsletterInfoText(): string {
    return this.startupService.getConfig().newsletterInfoText;
  }

  termsChanged(event: any): void {
    this.isTermsAccepted = event.currentTarget.checked;
  }

  newsletterChanged(event: any): void {
    this.isNewsletterAccepted = event.currentTarget.checked;
  }

  downloadTicket(): void {
    this.bookingService.downloadTicket(this.booking.id)
    .subscribe(res => {
      importedSaveAs(res, 'Ticket.pdf');
    });
  }

  downloadInvoice(): void {
    this.bookingService.downloadInvoice(this.booking.id)
    .subscribe(res => {
      importedSaveAs(res, 'Rechnung.pdf');
    });
  }

  get isTicketDownloadAllowed(): boolean {
    return this.startupService.getConfig().allowTicketDownload;
  }

  get isInvoiceDownloadAllowed(): boolean {
    return this.startupService.getConfig().allowInvoiceDownload;
  }

  get showNewsletter(): boolean {
    return !this.globals.getUser().newsletter && this.startupService.getConfig().enableNewsletter;
  }

  get bookingFetchLimitString(): string {
    return this.startupService.getConfig().bookingFetchLimitString;
  }

  get useBlockLogic(): boolean {
      return this.startupService.getConfig().useBlockLogic;
  }

  get isSeating(): boolean { return this.screening !== null && this.screening.saalArt === SaalArt.Sitzplaetze; }

  get isMitarbeiter(): boolean {
      const user = this.globals.getUser();
      if (user == null) {
        return false;
      }

      const isMitarbeiter = this.userService.hasUserRight(user, UserRight.Mitarbeiter);
      const isAdmin = this.userService.hasUserRight(user, UserRight.Admin);
      return isMitarbeiter || isAdmin;
  }
}
