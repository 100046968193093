import { User } from './user';
import { Subject } from 'rxjs';

export class Globals {
  private user: User;
  userSource: Subject<User> = new Subject<User>();
  userSource$ = this.userSource.asObservable();

  private autoLoginInProgress = false;
  autoLoginInProgressSource: Subject<boolean> = new Subject<boolean>();
  autoLoginInProgressSource$ = this.autoLoginInProgressSource.asObservable();

  setAutoLoginInProgress(inProgress: boolean): void {
    this.autoLoginInProgress = inProgress;
    this.autoLoginInProgressSource.next(inProgress);
  }

  isAutoLoginInProgress(): boolean {
    return this.autoLoginInProgress;
  }

  setUser(user: User): void {
    this.user = user;
    this.userSource.next(user);
  }

  getUser(): User {
    return this.user;
  }

  removeUser(): void {
    this.user = null;
    this.userSource.next(null);
  }

  isLoggedIn(): boolean {
    return this.user != null;
  }
}
