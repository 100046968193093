import { Kinoprogrammstatus } from './enums/kinoprogrammstatus';
import { Reservierungsstatus } from './enums/reservierungsstatus';
import { Zahlungsdienstleister } from './enums/zahlungsdienstleister';

export class Reservierung {
  constructor() {
    this.sitzIds = [];
  }

  id: string;
  userId: string;
  reservierungsName: string;
  reservierungsTelefon: string;
  reservierungsEmail: string;
  pdfName: string;
  reservierungBestaetigt: boolean;
  reservierungsCode: string;
  vorstellungsId: string;
  sitzIds: string[];
  reservierungszeitpunkt: Date;
  letzteAenderung: Date;
  kinoprogrammStatus: Kinoprogrammstatus;
  status: Reservierungsstatus;
  zahlungsreferenz: string;
  zahlungsdienstleister: Zahlungsdienstleister;
  zahlungsdienstleisterGebuehren: number;
  anzahlErwachsene: number;
  anzahlKinder: number;
  preisErwachsene: number;
  preisKinder: number;
  anzahlTicketDownloads: number;
  letzterTicketDownload: Date;
  isDeleted: boolean;
  anzahlGescannt: number;
}
