import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DataService } from '../services/storage.service';

@Injectable()
export class AuthAdminGuard implements CanActivate {

    constructor(private router: Router, private storage: DataService, private jwtHelper: JwtHelperService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.storage.hasToken()) {
          const token = this.storage.token;

          const isExpired = this.jwtHelper.isTokenExpired(token);
          const decodedToken = this.jwtHelper.decodeToken(token);
          if (!isExpired && (decodedToken.Admin || decodedToken.Mitarbeiter || decodedToken.Kinoprogramm)) {
            return true;
          }
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
